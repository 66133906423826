import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import http from "../../../config/axios";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { AuthContext } from "../../../context/AuthContext";

const CreateAccount = ({ mobile }) => {
  const auth = useContext(AuthContext);
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password_confirmation, setPassCon] = useState("");
  const [type, setType] = useState("password");
  const [type1, setType1] = useState("password");
  const { handleSubmit, register, errors } = useForm();

  const onSubmit = async (formData) => {
    setLoading(true);
    if (password !== password_confirmation) {
      setLoading(false);
      toast.error("Password does not match.");
      return;
    }

    http
      .post("/users/register", {
        firstName,
        lastName,
        email,
        password,
        phone: mobile,
      })
      .then(({ data }) => {
        http.setToken(data.token);
        localStorage.setItem("access_token", data.token);
        auth.onLoggedIn(data.user, true);
        setLoading(false);
        history.go("/");
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response.data.message);
      });
  };

  return (
    <>
      <p className="text-center mb-4">
        <i className="fa fa-check-circle" /> {mobile} <br />
        Phone Number Verified{" "}
      </p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-12 form-group">
            <label htmlFor>First Name:</label>
          </div>
          <div className="col-12 form-group">
            <input
              type="text"
              name="firstName"
              ref={register({ required: true })}
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              className="form-control"
              placeholder="Enter your first name"
            />
          </div>
          {errors.name && (
            <span className="text-danger">This field is required</span>
          )}
          <div className="col-12">
            <label htmlFor>Last Name:</label>
          </div>
          <div className="col-12 form-group">
            <input
              type="text"
              name="lastName"
              ref={register({ required: true })}
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              className="form-control"
              placeholder="Enter your last name"
            />
          </div>
          {errors.name && (
            <span className="text-danger">This field is required</span>
          )}
          <div className="col-12">
            <label htmlFor>Email:</label>
          </div>
          <div className="col-12 form-group">
            <input
              type="text"
              name="email"
              ref={register({
                required: "This field is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "invalid email address",
                },
              })}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="form-control"
              placeholder="Email Address  "
            />
          </div>
          {errors.email && (
            <span className="text-danger">{errors.email.message}</span>
          )}
          <div className="col-12">
            <label htmlFor>Password:</label>
          </div>
          <div className="col-12 form-group">
            <input
              type={type}
              name="password"
              ref={register({
                required: "This field is required",
                pattern: {
                  value: /.{6,32}$/i,
                  message: "Password must be atleast 6 character long",
                },
              })}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="form-control"
              placeholder="Enter Password"
            />
            <button
              onClick={() => {
                type === "password" ? setType("text") : setType("password");
              }}
              className="icon"
              type="button"
            >
              {type === "password" ? (
                <i className="fa fa-eye" />
              ) : (
                <i className="fa fa-eye-slash" />
              )}
            </button>
          </div>
          {errors.password && (
            <span className="text-danger">{errors.password.message}</span>
          )}
          <div className="col-12">
            <label htmlFor>Re-Enter Password:</label>
          </div>
          <div className="col-12 form-group">
            <input
              type={type1}
              name="cPassword"
              ref={register({ required: true })}
              value={password_confirmation}
              onChange={(e) => setPassCon(e.target.value)}
              className="form-control"
              placeholder="Re-Enter Password"
            />
            <button
              onClick={() => {
                type1 === "password" ? setType1("text") : setType1("password");
              }}
              className="icon"
              type="button"
            >
              {type1 === "password" ? (
                <i className="fa fa-eye" />
              ) : (
                <i className="fa fa-eye-slash" />
              )}
            </button>
          </div>
          {errors.cPassword && (
            <span className="text-danger">This field is required</span>
          )}
          <div className="col-12 form-group">
            <button
              type="submit"
              className={
                loading
                  ? `btn-disable form-control text-center`
                  : `pink-btn-nr form-control text-center`
              }
              disabled={loading}
            >
              {loading ? "Creating Account..." : "Create Account"}
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default CreateAccount;
