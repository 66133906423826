import React, { useContext, useState } from "react";
import { Redirect } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import CreateAccount from "./CreateAccount";
import SendVerification from "./SendVerification";
import VerifyNumber from "./VerifyNumber";

const Register = () => {
  const auth = useContext(AuthContext);

  const [activeStep, setActiveStep] = useState(0);
  const [mobile, setMobile] = useState("");

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <SendVerification
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            mobile={mobile}
            setMobile={setMobile}
          />
        );
      case 1:
        return (
          <VerifyNumber
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            mobile={mobile}
          />
        );
      case 2:
        return <CreateAccount mobile={mobile} />;
      default:
        throw new Error("Unknown step");
    }
  }

  if (auth.isAuthenticated) {
    return <Redirect to={{ pathname: "/" }} />;
  } else {
    return (
      <>
        <section className="login-banner">
          {/*profile start here*/}
          <div className="container">
            <div className="col-md-8 offset-md-2 col-12 ">
              <div className="login-main">
                <div className="login-inner register-page">
                  <div className="right">
                    <img
                      src="/images/pre-order.png"
                      className="img-fluid"
                      alt=""
                      width="100"
                    />
                    <h3 className="pink text-center">Registeration</h3>
                    <h6 className="text-center">Step {activeStep + 1}</h6>
                    {getStepContent(activeStep)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
};

export default Register;
