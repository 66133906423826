import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import http from '../config/axios';
import CategoryCard from './mics/CategoryCard';

const Category = () => {
	const [categories, setCategories] = useState([]);

	const loadCategories = async () => {
		try {
			const { data } = await http.get(`/categories`);
			setCategories(data.data);
		} catch (error) {
			toast.warn(error.message);
		}
	};

	useEffect(() => {
		window.scrollTo(0, 0);
		loadCategories();
	}, []);

	return (
		<div>
			{/*inner-header-section start here*/}
			<section className='inner-header-section'>
				<div className='container'>
					<div className='row'>
						<div className='col-xl-7 col-lg-9 col-12'>
							<h2>Categories</h2>
							<h4 className='pink mt-4'>Discover the unique village items!</h4>
						</div>
					</div>
				</div>
			</section>
			{/*inner-header-section end here*/}
			{/*category start here*/}
			<section className='category'>
				<div className='container'>
					<div className='row'>
						<div className='col-12'>
							<h3>Shop By Category</h3>
						</div>
					</div>
					<div className='row'>
						{categories &&
							categories.map((category) => (
								<CategoryCard category={category} key={category.id} />
							))}
					</div>
				</div>
			</section>
		</div>
	);
};

export default Category;
