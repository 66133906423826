import React, { useState, useEffect, useLayoutEffect } from "react";
import { QueryToOBJ, OBJtoQuery } from "../../utils/query_to_object";
import { useLocation, useHistory } from "react-router-dom";
import http from "../../config/axios";
import { useDispatch } from "react-redux";
import { getSellers } from "../../store/actions/sellerActions";
import { Typeahead } from "react-bootstrap-typeahead";
import { Form } from "react-bootstrap";

const ProductFilters = ({ page, setPage }) => {
  let history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const defaultFilters = QueryToOBJ(location.search);

  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState(defaultFilters.category || "");
  const [keyword, setKeyword] = useState("");

  const [sort, setSort] = useState(
    defaultFilters.sort ? defaultFilters.sort : ""
  );
  const [query] = useState(defaultFilters.query ? defaultFilters.query : "");

  const prepareQueryParams = () => {
    const filter = {
      isProductApproved: true,
      category: category,
      sort: sort,
      query: query,
      keyword: keyword,
    };
    history.push({
      pathname: "/products",
      search: OBJtoQuery(filter),
    });
  };

  let array = [];

  const onInputChange = (event) => {
    setPage(1);
    event.find((item) => {
      if (!array.includes(item.name)) {
        array.push(item.name);
      }
      return undefined;
    });
    setCategory(array.join(","));
  };

  useLayoutEffect(() => {
    prepareQueryParams();
  }, [category, dispatch, keyword]);

  useEffect(() => {
    dispatch(getSellers());
    http.get("/categories").then(({ data }) => {
      setCategories(data.data);
    });
  }, [dispatch]);

  return (
    <div className="left">
      <label htmlFor="Shop By ">Filters</label>
      <Form className="d-flex">
        <Form.Control
          type="search"
          placeholder="Search Products"
          aria-label="Search Products"
          onChange={(e) => setKeyword(e.target.value)}
        />
      </Form>
      <form action="">
        <div className="row">
          <div className="col-12 form-group">
            <Typeahead
              id="vd-id"
              labelKey="name"
              multiple
              onChange={(event) => onInputChange(event)}
              options={categories}
              placeholder="Filter by Categories"
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default ProductFilters;
