import React, { useState } from "react";
import http from "../config/axios";
import CheckoutCard from "./mics/CheckoutCard";

import { toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import CartItem from "./mics/CartItem";

const MyCart = () => {
  const cart = useAuth();
  const history = useHistory();
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");

  const onLogin = (e) => {
    e.preventDefault();
    http
      .post("/users/login", { email: loginEmail, password: loginPassword })
      .then(({ data }) => {
        localStorage.setItem("access_token", data.token);

        cart.onLoggedIn(data, true);
        window.$(".review-modal.check-out-popup").modal("hide");
        history.go("/check-out");
      })
      .catch((err) => {
        toast.error("Invalid Credentials", err);
      });
  };

  const cartItems = cart.cart;
  return (
    <div>
      <section className="inner-header-section">
        <div className="container">
          <div className="row">
            <div className="col-xl-7 col-lg-9 col-12">
              <h2>My Cart</h2>
              <h4 className="pink mt-4">Discover the unique village items!</h4>
            </div>
          </div>
        </div>
      </section>
      {/*inner-header-section end here*/}
      <section className="my-cart">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3>All Items</h3>
            </div>
          </div>
          <form>
            <div className="row">
              <div className="col-12 col-lg-8 table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Product</th>
                      <th>price</th>
                      <th>Quantity</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cartItems.map((item) => {
                      return <CartItem {...item} {...true} key={item.id} />;
                    })}
                  </tbody>
                </table>
              </div>
              <CheckoutCard showEdit={false} />
            </div>
          </form>
        </div>
      </section>
      {/*check-out-popup start here*/}
      <div
        className="modal fade review-modal check-out-popup"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content common-modal">
            <div className="forget-pass ">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                {" "}
                <span aria-hidden="true">×</span>{" "}
              </button>
              <div className="modal-body p-0">
                <div className="row">
                  <div className="col-12 ">
                    <div className="row">
                      <div className="col-12 text-center">
                        <img
                          src="images/pre-order.png"
                          className="img-fluid"
                          alt=""
                          width="100"
                        />
                        <h5>Please Login or Register to Checkout</h5>
                      </div>
                    </div>
                    <form onSubmit={onLogin}>
                      <div className="form-group">
                        <label>Email Address</label>
                        <input
                          type="email"
                          value={loginEmail}
                          onChange={(e) => setLoginEmail(e.target.value)}
                          className="form-control"
                          placeholder="Enter Email"
                        />
                      </div>
                      <div className="form-group">
                        <label>Enter Password</label>
                        <input
                          type="password"
                          value={loginPassword}
                          onChange={(e) => setLoginPassword(e.target.value)}
                          className="form-control"
                          placeholder="Enter Password"
                        />
                      </div>
                      <p>
                        New to VillageDukaan?{" "}
                        <Link to="/register" className="pink">
                          Sign Up
                        </Link>
                      </p>
                      <button
                        type="submit"
                        className="form-control pink-btn-nr"
                        aria-label="Close"
                      >
                        Login{" "}
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyCart;
