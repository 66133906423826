import React from "react";
import { NavLink } from "react-router-dom";
import http from "../../config/axios";

class Profile extends React.Component {
  state = {
    profile: null,
  };
  onPageLoad = async () => {
    const { data } = await http.get(`/users/currentuser`);
    this.setState({
      profile: data.currentUser,
    });
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    this.onPageLoad();
  }
  render() {
    const { profile } = this.state;

    return profile != null ? (
      <div>
        <section className="inner-header-section">
          <div className="container">
            <div className="row">
              <div className="col-xl-7 col-lg-9 col-12">
                <h2>Profile</h2>
                <h4 className="pink mt-4">
                  Discover the unique village items!
                </h4>
              </div>
            </div>
          </div>
        </section>
        {/*inner-header-section end here*/}
        {/*profile start here*/}
        <section className="profile-main p-80">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h5>Profile Information</h5>
              </div>
            </div>
            <div className="profile-top">
              <div className="row">
                <div className="col-lg-9 col-12 ">
                  <div className="d-sm-flex justify-content-between align-items-center">
                    <div>
                      <div className="media align-items-center">
                        <img
                          src={
                            profile.image ||
                            `https://ui-avatars.com/api/${profile.firstName}/64/615bbf/fff/2/0.5/true/true/true`
                          }
                          className="img-fluid img-rounded"
                          alt=""
                        />
                        <div className="media-body">
                          <h4 className="yel">
                            {profile.firstName}{" "}
                            {profile.lastName !== "null"
                              ? profile.lastName
                              : ""}
                          </h4>
                          <p>
                            <i className="fa fa-phone" />
                            Email:{" "}
                            {profile.email !== "null" ? profile.email : "N/A"}
                          </p>
                          <p>
                            <i className="fa fa-envelope" />
                            Phone:{" "}
                            {profile.phone !== "null" ? profile.phone : "N/A"}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div>
                      <NavLink to="/profile/edit" className="pink-btn">
                        edit profile
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="profile-bottom mt-5">
              <div className="row ">
                <div className="col-12 col-md-6">
                  <div className="row">
                    <div className="col-lg-6 col-12">
                      <p>first Name</p>
                      <h5>
                        {profile.firstName !== "null"
                          ? profile.firstName
                          : "N/A"}
                      </h5>
                    </div>
                    <div className="col-lg-6 col-12">
                      <p>Last Name</p>
                      <h5>
                        {profile.lastName !== "null" ? profile.lastName : "N/A"}
                      </h5>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <p>Contact</p>
                      <h5>
                        {profile.phone !== "null" ? profile.phone : "N/A"}
                      </h5>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <p>Password</p>
                      <h5>
                        {profile.password !== "null" ? profile.password : "N/A"}
                      </h5>
                      <p>To change password click Edit profile</p>
                    </div>
                  </div>
                  <div>
                    <NavLink to={"/profile/delete"} className="pink-btn">
                      Delete Account
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    ) : null;
  }
}

export default Profile;
