import React, { Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import http from "../../config/axios";

const TermsOfUsingWebsite = () => {
  const [org, setOrg] = useState();

  const loadOrg = () => {
    http
      .get("/contacts")
      .then((resp) => {
        setOrg(resp.data.data[0]);
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    loadOrg();
  }, []);
  return (
    <div className="">
      {/*inner-header-section start here*/}
      <section className="inner-header-section">
        <div className="container">
          <div className="row">
            <div className="col-xl-7 col-lg-9 col-12">
              <h2>Terms of using website</h2>
              <h4 className="pink mt-4">Discover the unique village items!</h4>
            </div>
          </div>
        </div>
      </section>
      {/*inner-header-section end here*/}
      <section className="default p-100">
        <div className="container">
          <div className="row">
            <div className="col-12">
              {!org ? (
                <Spinner />
              ) : (
                org.termsOfUsing.map((item, key) => (
                  <Fragment key={key}>
                    <h5 className="mb-3">{item.header}</h5>
                    <p>{item.paragraph}</p>
                  </Fragment>
                ))
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default TermsOfUsingWebsite;
