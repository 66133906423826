import React from "react";

const Spinner = () => {
  return (
    <section className="inner-header-section">
      <div className="container">
        <div className="spinner-container">
          <div className="spinner"></div>
        </div>
      </div>
    </section>
  );
};

export default Spinner;
