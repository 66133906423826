import http from "../../config/axios";
import {
  TOP_PRODUCT_FAIL,
  TOP_PRODUCT_REQUEST,
  TOP_PRODUCT_SUCCESS,
  RECENT_PRODUCT_REQUEST,
  RECENT_PRODUCT_SUCCESS,
  RECENT_PRODUCT_FAIL,
  ALL_PRODUCTS_REQUEST,
  ALL_PRODUCTS_SUCCESS,
  ALL_PRODUCTS_FAIL,
  PRODUCT_REQUEST,
  PRODUCT_SUCCESS,
  PRODUCT_FAIL,
  FAMOUS_PRODUCT_REQUEST,
  FAMOUS_PRODUCT_SUCCESS,
  FAMOUS_PRODUCT_FAIL,
} from "../types";
import Decrypt from "../../utils/Decrypt";

export const getAllProducts =
  (search = "", page, limit) =>
  async (dispatch) => {
    try {
      dispatch({
        type: ALL_PRODUCTS_REQUEST,
      });

      const { data } = await http.get(
        `/products${
          search ? search : "?isProductApproved=true"
        }&page=${page}&limit=${limit}&fields=title,catgeory,slug,brand,price,images`
      );

      dispatch({
        type: ALL_PRODUCTS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ALL_PRODUCTS_FAIL,
        payload: error.response,
      });
    }
  };

export const productDetails = (slug) => async (dispatch) => {
  try {
    dispatch({
      type: PRODUCT_REQUEST,
    });

    const { data } = await http.get(`/products/${slug}`);

    dispatch({
      type: PRODUCT_SUCCESS,
      payload: Decrypt(data.data),
    });
  } catch (error) {
    dispatch({
      type: PRODUCT_FAIL,
      payload: error.response,
    });
  }
};

export const getTopProducts = () => async (dispatch) => {
  try {
    dispatch({
      type: TOP_PRODUCT_REQUEST,
    });

    const { data } = await http.get(
      "/products/top/products?isProductApproved=true&fields=title,catgeory,slug,brand,price,images"
    );

    dispatch({
      type: TOP_PRODUCT_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: TOP_PRODUCT_FAIL,
      payload: error.response.data,
    });
  }
};

export const getFamousProducts = () => async (dispatch) => {
  try {
    dispatch({
      type: FAMOUS_PRODUCT_REQUEST,
    });

    const { data } = await http.get(
      "/products?isProductApproved=true&famousProduct=true&fields=title,catgeory,slug,brand,price,images"
    );

    dispatch({
      type: FAMOUS_PRODUCT_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: FAMOUS_PRODUCT_FAIL,
      payload: error.response.data,
    });
  }
};

export const getRecentProducts = () => async (dispatch) => {
  try {
    dispatch({
      type: RECENT_PRODUCT_REQUEST,
    });

    const { data } = await http.get(
      "/products/recent/products?isProductApproved=true&fields=title,catgeory,slug,brand,price,images"
    );

    dispatch({
      type: RECENT_PRODUCT_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: RECENT_PRODUCT_FAIL,
      payload: error.response.data,
    });
  }
};
