import React, { useState } from "react";
import { Link } from "react-router-dom";
import http from "../../config/axios";
import { toast } from "react-toastify";
import { useEffect } from "react";
import Spinner from "../mics/Spinner";

const Footer = () => {
  const [email, setEmail] = useState("");
  const onSubscribe = () => {
    http
      .post("/subscribe", { email })
      .then((res) => {
        toast.success("Subscribed successfully");
      })
      .catch((err) => {
        toast.error(
          err.response.data.errors.email[0] || "Something went wrong"
        );
      });
  };

  const [org, setOrg] = useState();

  const loadOrg = () => {
    http
      .get("/contacts")
      .then((resp) => {
        setOrg(resp.data.data[0]);
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  useEffect(() => {
    loadOrg();
  }, []);

  if (!org) {
    return <Spinner />;
  }

  return (
    <footer>
      <div className="container">
        <div className="footer-top">
          <div className="row">
            <div className="col-lg-3 col-sm-6 col-12">
              <div className="footer footer-1">
                {" "}
                <Link to="/">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/logo.png`}
                    className="img-fluid"
                    alt=""
                  />
                </Link>
                <ul>
                  <li>
                    <a
                      href="https://g.page/VillageDukaan?share"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Address: {org.address}
                    </a>
                  </li>
                  <li>
                    <a href="mailto:support@villagedukaan.com">
                      Email : {org.email}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-12">
              <div className="footer footer-2">
                <h6>QUICK LINKS</h6>
                <ul>
                  <li>
                    <Link to="/about-us">
                      <i className="fa fa-angle-right" />
                      About VillageDukaan
                    </Link>
                  </li>
                  {/* <li><a href="#/"><i className="fa fa-angle-right" />Careers</a></li> */}
                  <li>
                    <Link to="/terms-of-using-website">
                      <i className="fa fa-angle-right" />
                      Terms Of Using Website
                    </Link>
                  </li>
                  <li>
                    <Link to="/your-security">
                      <i className="fa fa-angle-right" />
                      Your Security
                    </Link>
                  </li>
                  <li>
                    <Link to="/cookie-policy">
                      <i className="fa fa-angle-right" />
                      Cookie Policy
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-12">
              <div className="footer footer-3">
                <h6>Customer Service</h6>
                <ul>
                  <li>
                    <Link to="/contact">
                      <i className="fa fa-angle-right" />
                      Contact us
                    </Link>
                  </li>
                  <li>
                    <Link to="/delivery-information">
                      <i className="fa fa-angle-right" />
                      Our Delivery Information
                    </Link>
                  </li>
                  <li>
                    <Link to="/return-policy">
                      <i className="fa fa-angle-right" />
                      Our Return Policy
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-12">
              <div className="footer footer-4">
                <h6>Get In Touch</h6>
                <p>Enter your email and we'll send you more information.</p>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="form-control"
                  placeholder="Enter Your Email"
                />
                <button
                  onClick={onSubscribe}
                  type="submit"
                  className="pink-btn"
                >
                  Subscribe
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="row">
            <div className="col-12 col-md-6">
              <p>
                © Copyright {new Date().getFullYear()} {}. All Right Reserved.
              </p>
            </div>
            <div className="col-12 col-md-6">
              <ul>
                <li>
                  <a href={org.facebook} target="_blank" rel="noreferrer">
                    <i className="fab fa-facebook-f" />
                  </a>
                </li>
                <li>
                  <a href={org.twitter} target="_blank" rel="noreferrer">
                    <i className="fab fa-twitter" />
                  </a>
                </li>
                <li>
                  <a href={org.linkedin} target="_blank" rel="noreferrer">
                    <i className="fab fa-linkedin-in" />
                  </a>
                </li>
                <li>
                  <a href={org.youtube} target="_blank" rel="noreferrer">
                    <i className="fab fa-youtube" />
                  </a>
                </li>
                <li>
                  <a href={org.instagram} target="_blank" rel="noreferrer">
                    <i className="fab fa-instagram" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
