import React from "react";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import useAuth from "../../hooks/useAuth";
import AddressForm from "./AddressForm";
import ReviewYourOrder from "./ReviewYourOrder";

const Checkout = () => {
  const { cart, user } = useAuth();
  const [activeStep, setActiveStep] = React.useState(0);
  const [deliveryAddress, setDeliveryAddress] = React.useState();

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <AddressForm
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            setDeliveryAddress={setDeliveryAddress}
            user={user}
          />
        );
      case 1:
        return (
          <ReviewYourOrder
            deliveryAddress={deliveryAddress}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            setDeliveryAddress={setDeliveryAddress}
          />
        );
      case 2:
        return <AddressForm />;
      default:
        throw new Error("Unknown step");
    }
  }

  if (cart && cart.length === 0) {
    toast.error("Please add products to checkout!");
    return <Redirect to={{ pathname: "/products" }} />;
  } else {
    return (
      <>
        <section className="inner-header-section">
          <div className="container">
            <div className="row">
              <div className="col-xl-7 col-lg-9 col-12">
                <h2>Checkout</h2>
                <h4 className="pink mt-4">
                  Discover the unique village items!
                </h4>
              </div>
            </div>
          </div>
        </section>
        <section className="check-out p-70">
          <div className="container">{getStepContent(activeStep)}</div>
        </section>
      </>
    );
  }
};

export default Checkout;
