import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import http from "../../config/axios";
import TableHeader from "../mics/TableHeader";
import { toast } from "react-toastify";
import { NavLink } from "react-router-dom";
import { useCallback } from "react";
import ReactPaginate from "react-paginate";

const Order_Log = (props) => {
  const [orders, setOrders] = useState(null);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);

  const onOrderLog = useCallback(async () => {
    const { data } = await http.get(
      `orders/user/orders?page=${page}&limit=${limit}&fields=slug,createdAt,totalPrice`
    );
    setOrders(data);
  }, [limit, page]);
  useEffect(() => {
    window.scrollTo(0, 0);
    onOrderLog();
  }, [onOrderLog]);

  const changePerPage = (e) => setLimit(e.target.value);

  const onPage = (selectedPage) => setPage(selectedPage.selected + 1);
  const onViewOrder = (id) => {
    try {
      props.history.push({
        pathname: `/order-detail/${id}`,
      });
    } catch (error) {
      toast.error("Order Not Found");
    }
  };
  return (
    <div>
      <section className="inner-header-section">
        <div className="container">
          <div className="row">
            <div className="col-xl-7 col-lg-9 col-12">
              <h2>Order log</h2>
              <h4 className="pink mt-4">Discover the unique village items!</h4>
            </div>
          </div>
        </div>
      </section>
      <section className="order-log">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3>Order Log List</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="maain-tabble table-responsive">
                <div
                  id="DataTables_Table_0_wrapper"
                  className="dataTables_wrapper container-fluid dt-bootstrap4 no-footer"
                >
                  <TableHeader onChangePerPag={changePerPage} />
                  <div className="row">
                    <div className="col-sm-12">
                      <table
                        className="table table-striped zero-configuration dataTable no-footer"
                        id="DataTables_Table_0"
                        role="grid"
                        aria-describedby="DataTables_Table_0_info"
                      >
                        <thead>
                          <tr role="row">
                            <th
                              tabIndex={0}
                              aria-controls="DataTables_Table_0"
                              rowSpan={1}
                              colSpan={1}
                              aria-sort="ascending"
                              aria-label="Order ID: activate to sort column descending"
                              style={{ width: "198px" }}
                            >
                              Order ID
                            </th>
                            <th
                              tabIndex={0}
                              aria-controls="DataTables_Table_0"
                              rowSpan={1}
                              colSpan={1}
                              aria-label="price: activate to sort column ascending"
                              style={{ width: "125px" }}
                            >
                              Order Total
                            </th>
                            <th
                              tabIndex={0}
                              aria-controls="DataTables_Table_0"
                              rowSpan={1}
                              colSpan={1}
                              aria-label="Date: activate to sort column ascending"
                              style={{ width: "117px" }}
                            >
                              Date
                            </th>
                            <th
                              tabIndex={0}
                              aria-controls="DataTables_Table_0"
                              rowSpan={1}
                              colSpan={1}
                              aria-label="Tracking: activate to sort column ascending"
                              style={{ width: "100px" }}
                              className="text-center"
                            >
                              Tracking
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {orders?.data.map((order) => (
                            <tr
                              style={{ cursor: "pointer" }}
                              role="row"
                              className="odd"
                              key={`order_${order.slug}`}
                            >
                              <td
                                onClick={() => onViewOrder(order.slug)}
                                className="sorting_1"
                              >
                                <NavLink
                                  to={`/track-order/${order.slug}`}
                                  className="text-center"
                                >
                                  {order.slug}
                                </NavLink>
                              </td>
                              <td>{order.totalPrice}</td>
                              <td>
                                {new Date(order.createdAt).toDateString(
                                  undefined,
                                  { timeZone: "Asia/Kolkata" }
                                )}
                              </td>
                              <td className="text-center">
                                <NavLink to={`/track-order/${order.slug}`}>
                                  Track Order
                                </NavLink>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {orders ? (
                    <div className="row">
                      <div className="col-12">
                        <div
                          className="dataTables_paginate paging_simple_numbers"
                          id="DataTables_Table_0_paginate"
                        >
                          <ReactPaginate
                            containerClassName="pagination justify-content-end"
                            pageClassName="paginate_button page-item"
                            pageLinkClassName="page-link"
                            activeClassName="active"
                            previousClassName="paginate_button page-item previous"
                            previousLinkClassName="page-link"
                            nextClassName="paginate_button page-item next"
                            nextLinkClassName="page-link"
                            activeLinkClassName="paginate_button page-item"
                            pageCount={orders.pages}
                            onPageChange={onPage}
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default withRouter(Order_Log);
