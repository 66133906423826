import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import http from "../../config/axios";
import SelectAddress from "./SelectAddress";
import CheckoutCard from "../mics/CheckoutCard";
import useAuth from "../../hooks/useAuth";
import displayRazorpay from "../../utils/PaymentGateway";
import CartItem from "../mics/CartItem";
import { getProductDetails } from "../../services/products";
import { useCallback } from "react";
import Decrypt from "../../utils/Decrypt";

const ReviewYourOrder = ({
  deliveryAddress,
  setDeliveryAddress,
  activeStep,
  setActiveStep,
}) => {
  const [address, setAddress] = useState();
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(false);

  const [selectedSize, setSelectedSize] = useState([]);
  const [total, setTotal] = useState(0);

  const auth = useAuth();

  const cartItems = auth.cart;

  const loadCartItems = useCallback(() => {
    cartItems.map((item) => {
      return getProductDetails(item.id).then((data) => {
        const selected = data.variants[0].sizes.find(
          (o) => o._id === item.size
        );
        selected["qty"] = item.qty;
        selected["title"] = data.title;
        selected["seller"] = data.userId;
        selected["image"] = data.images[0].url;
        setSelectedSize((selectedSize) => [...selectedSize, selected]);
      });
    });
  }, [cartItems]);

  useEffect(() => {
    setSelectedSize([]);
    loadCartItems();
  }, [auth, loadCartItems]);

  useEffect(() => {
    const cartTotal = selectedSize.reduce((total, item) => {
      return total + item.qty * item.price;
    }, 0);
    setTotal(Math.round(cartTotal));
  }, [selectedSize]);

  const history = useHistory();

  const loadUser = async () => {
    try {
      const { data } = await http.get(`/users/currentuser`);
      setUser(data.currentUser);
    } catch (error) {
      toast.warn(error.message);
    }
  };

  const loadAddress = async () => {
    try {
      const { data } = await http.get(`/addresses/${deliveryAddress}`);
      setAddress(Decrypt(data.data));
    } catch (error) {
      toast.warn(error.message);
    }
  };

  const changeDeliveryAddress = () => {
    try {
      setDeliveryAddress("");
      setActiveStep(activeStep - 1);
      toast.success("Add/Choose address");
    } catch (error) {
      toast.warn(error.message);
    }
  };

  const groupBySeller = cartItems.reduce((group, seller) => {
    const { shippedBy } = seller;
    group[shippedBy] = group[shippedBy] ?? [];
    group[shippedBy].push(seller);
    return group;
  }, {});

  const placeOrders = async () => {
    setLoading(true);
    try {
      await displayRazorpay(
        user,
        total,
        address,
        groupBySeller,
        auth,
        history,
        selectedSize
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    loadAddress();
    loadUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="tab">
      <div className="row">
        <div className="col-12">
          <h3>Review and Place Order</h3>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-lg-8 col-md-12">
          <div className="product-bottom">
            <div className="row">
              {address && (
                <SelectAddress
                  address={address}
                  key={address.id}
                  requireAddressChange={true}
                  changeDeliveryAddress={changeDeliveryAddress}
                />
              )}
            </div>
          </div>
        </div>
        <CheckoutCard showEdit={true} />
      </div>
      <section className="my-cart">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3>Order Items</h3>
              <div className="container">
                <form>
                  <div className="row">
                    <div className="col-12 col-lg-8 table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Product</th>
                            <th>price</th>
                            <th>Quantity</th>
                            <th>Total</th>
                          </tr>
                        </thead>
                        {Object.keys(groupBySeller).map((key) => {
                          return (
                            <tbody>
                              <span className="pink">Shipped by {key}</span>
                              <br />
                              {groupBySeller[key].map((product) => {
                                return (
                                  <CartItem {...product} showButton={false} />
                                );
                              })}
                            </tbody>
                          );
                        })}
                      </table>
                    </div>
                  </div>
                </form>
              </div>
              <div className="media align-items-center">
                <div className="media-body">
                  <button
                    className={
                      loading ? `btn text-center text-danger` : `pink-btn`
                    }
                    disabled={loading}
                    onClick={placeOrders}
                  >
                    {loading
                      ? "Taking to Payment Options..."
                      : "Place Order And Pay"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ReviewYourOrder;
