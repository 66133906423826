import React, { useState, useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";
import ProductImages from "./mics/ProductImages";
import OwlCarousel from "react-owl-carousel";
import { toast } from "react-toastify";
import useAuth from "../hooks/useAuth";
import Spinner from "./mics/Spinner";
import { useDispatch, useSelector } from "react-redux";
import HomeProductCard from "./mics/HomeProductCard";
import {
  getTopProducts,
  productDetails,
} from "../store/actions/productActions";

const ProductDetails = () => {
  const { id } = useParams();
  const [qty, setQty] = useState(1);
  const [selectedSize, setSelectedSize] = useState(null);

  const dispatch = useDispatch();

  const productTopRated = useSelector((state) => state.topProducts);
  const { topProductsList } = productTopRated;

  const productInfo = useSelector((state) => state.product);
  const { product } = productInfo;

  const { onCartItemAdded } = useAuth();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!product.slug || product.slug !== id) {
      dispatch(productDetails(id));
    } else {
      setSelectedSize(product?.variants[0].sizes[0]);
    }
  }, [id, dispatch, product.slug, product.variants]);

  useEffect(() => {
    dispatch(getTopProducts());
  }, [dispatch]);

  const increaseQty = () => {
    setQty(qty + 1);
  };

  const decreaseQty = () => {
    setQty(qty - 1);
  };

  const addToCart = async () => {
    if (!selectedSize) {
      toast.warn("Please select an option");
      window.$(".pre-order-modal").modal("hide");
      return;
    }

    onCartItemAdded({
      id: product.id,
      size: selectedSize._id,
      qty,
      shippedBy: product.shippedBy,
      seller: product.userId,
    });

    toast.success("Item Added to your basket.");
  };

  const responsive = {
    0: {
      items: 1,
    },
    575: {
      items: 1,
    },
    600: {
      items: 2,
    },
    1000: {
      items: 4,
    },
  };

  return (
    <>
      {!product || !selectedSize ? (
        <Spinner />
      ) : (
        <>
          <section className="inner-header-section">
            <div className="container">
              <div className="row">
                <div className="col-xl-7 col-lg-9 col-12">
                  <h2>Products Details</h2>
                  <h4 className="pink mt-4">
                    Discover the unique village items!
                  </h4>
                </div>
              </div>
            </div>
          </section>
          {/*inner-header-section end here*/}
          {/*product-detail start here*/}
          <section className="product-detail-main pad-60">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <nav className="breadcrumb bg-white">
                    <NavLink className="breadcrumb-item" to="/products">
                      All Products
                    </NavLink>
                    <span className="breadcrumb-item active">
                      Product details
                    </span>
                  </nav>
                </div>
              </div>
              <div className="product-detail">
                <div className="row">
                  <div className="col-lg-6 col-12">
                    <ProductImages images={product.images} />
                  </div>
                  <div className="col-lg-6 col-12">
                    <div className="right">
                      <div className="top">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="w-75">
                            <h4>{product.title}</h4>
                            <h6>Category: {product.category} </h6>
                          </div>
                          <div className="w-25">
                            <div className="own-by">
                              <p>Owned by:</p>
                              <img
                                src={`${process.env.PUBLIC_URL}/images/logo.png`}
                                className="img-fluid"
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="middle">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: product ? product.description : null,
                          }}
                        ></div>
                        <h3>{selectedSize.price}</h3>
                        <div className="row">
                          <div className="col-sm-6 col-12">
                            <label style={{ display: "block" }}>
                              Select an option
                            </label>
                            <div
                              className="btn-group btn-group-toggle"
                              data-toggle="buttons"
                            >
                              {product.variants.map((variant) =>
                                variant.sizes.map((size, i) => (
                                  <label
                                    className={
                                      size.weight === selectedSize.weight
                                        ? "active btn btn-secondary"
                                        : "btn btn-secondary"
                                    }
                                    key={i}
                                    htmlFor={`optionfor${i}`}
                                    onClick={() => setSelectedSize(size)}
                                  >
                                    <input
                                      type="radio"
                                      name={size.weight}
                                      id={`optionfor${i}`}
                                      autoComplete="off"
                                    />
                                    {size.weight}
                                  </label>
                                ))
                              )}
                            </div>
                          </div>
                          <div className="col-sm-6 col-12">
                            <div className="quantity">
                              <label>Quantity:</label>
                              <button className="minus" onClick={increaseQty}>
                                <i className="fa fa-plus-circle" />
                              </button>

                              <input type="number" value={qty} readOnly />

                              <button
                                className="plus"
                                onClick={decreaseQty}
                                disabled={qty <= 1}
                              >
                                <i className="fa fa-minus-circle" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="bottom">
                        <div className="row">
                          <div className="col-12 col-sm-6">
                            <button
                              type="button"
                              className="pink-btn shopping"
                              onClick={addToCart}
                            >
                              <i className="fas fa-shopping-basket" /> Add to
                              cart
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="related-products detailed">
            <div className="container">
              {topProductsList.length > 0 ? (
                <>
                  <div className="row">
                    <div className="col-12 text-center">
                      <h2>Top Products</h2>
                    </div>
                  </div>
                  <div
                    className="product-bottom"
                    id="related-detailed-products"
                  >
                    {topProductsList ? (
                      <OwlCarousel
                        items={4}
                        id="buy-slider-sfa"
                        className="corona-thumb-slide slider-arrow owl-carousel"
                        loop
                        margin={10}
                        autoplay={true}
                        responsive={responsive}
                      >
                        {topProductsList.map((product) => (
                          <HomeProductCard
                            key={product.id}
                            home={false}
                            product={product}
                          />
                        ))}
                      </OwlCarousel>
                    ) : null}
                  </div>
                </>
              ) : null}
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default ProductDetails;
