import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import http from '../../../config/axios'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import CountryCodes from '../../../utils/CountryCodes'

const SendVerification = ({ activeStep, setActiveStep, setMobile, mobile }) => {
  const { handleSubmit, register, errors } = useForm()

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [phone, setPhone] = useState('')
  const [code, setCode] = useState('91')

  const verifyNumber = () => {
    setMobile(`+${code}${phone}`)
    setLoading(true)
    http
      .post(`/users/getCode`, {
        phone: `+${code + phone}`,
        channel: 'sms'
      })
      .then(res => {
        setLoading(false)
        setActiveStep(activeStep + 1)
      })
      .catch(err => {
        setLoading(false)
        toast.error(err.response.data.message || 'Phone Number is required')
      })
  }

  return (
    <>
      <form onSubmit={handleSubmit(verifyNumber)}>
        <div className="row">
          <div className="col-12">
            <p className="mt-4">Enter your mobile number to receive a verification code.</p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 form-group d-flex justify-content-start">
            <select value={code} onChange={e => setCode(`+${e.target.value}`)}>
              {CountryCodes.map(item => (
                <option key={item.value} value={item.value}>
                  +{item.value}
                </option>
              ))}
            </select>
            <input
              type="number"
              name="number"
              ref={register({ required: true })}
              required
              style={{ marginLeft: '-20px' }}
              value={phone}
              onChange={e => {
                setPhone(e.target.value)
              }}
              className="form-control"
              placeholder="Enter mobile number"
            />
            {errors.number && <span className="text-danger">This field is required</span>}
          </div>
        </div>
        <button
          type="submit"
          className={loading ? `btn-disable form-control text-center` : `pink-btn-nr form-control text-center`}
          disabled={loading}
        >
          {loading ? 'Sending Verification Code ...' : 'Get Verification Code'}
        </button>
      </form>
      <p className="text-center">
        Already have an account?{' '}
        <NavLink to="/login" className="yel">
          SIGN IN
        </NavLink>{' '}
      </p>
    </>
  )
}

export default SendVerification
