import React, { useState, useEffect } from "react";
import { useCallback } from "react";
import useAuth from "../../hooks/useAuth";
import { getProductDetails } from "../../services/products";
import Price from "./Price";
const CartItem = (item) => {
  const cart = useAuth();
  const [product, setProduct] = useState(null);
  const [selectedSize, setSelectedSize] = useState(null);

  const loadProductDetails = useCallback(() => {
    getProductDetails(item.id).then((data) => {
      setProduct(data);
      const selected = data.variants[0].sizes.find((o) => o._id === item.size);
      setSelectedSize(selected);
    });
  }, [item.id, item.size]);
  useEffect(() => {
    loadProductDetails();
  }, [loadProductDetails]);

  const increseQty = async (item) => {
    const qty = parseInt(item.qty) + 1;
    cart.onCartItemAdded({
      id: item.id,
      size: item.size,
      qty,
      shippedBy: item.shippedBy,
      seller: item.seller,
    });
  };

  const decreseQty = async (item) => {
    const qty = parseInt(item.qty) - 1;

    if (qty < 1) return;
    cart.onCartItemAdded({
      id: item.id,
      size: item.size,
      qty,
      shippedBy: item.shippedBy,
      seller: item.seller,
    });
  };

  return (
    <>
      {product && selectedSize && (
        <tr>
          <td>
            <div className="media align-items-center d-sm-flex d-block d-sm-text-left">
              <img
                src={`${process.env.REACT_APP_CLOUDFRONT_PUBLIC_CDN}/${product.images[0].url}`}
                className="img-fluid"
                alt=""
              />
              <div className="media-body">
                <div className="justify-content-between align-items-center d-sm-flex d-block d-sm-text-left">
                  <h4 className="text-over">{product.title}</h4>
                </div>
                <h6>{selectedSize.weight}</h6>
              </div>
            </div>
          </td>
          <td>
            <Price option={selectedSize} price={selectedSize.price} qty={1} />
          </td>
          <td>
            <div className="quantity">
              <button
                type="button"
                className="plus"
                onClick={() => decreseQty(item)}
              >
                <i className="fa fa-minus-circle" />
              </button>
              <input
                type="number"
                value={item.qty}
                readOnly
                placeholder={item.qty}
              />
              <button
                type="button"
                className="minus"
                onClick={() => increseQty(item)}
              >
                <i className="fa fa-plus-circle" />
              </button>
            </div>
          </td>
          <td>
            <div className="d-flex justify-content-between">
              <Price
                option={selectedSize}
                price={selectedSize.price}
                qty={item.qty}
              />
              <button
                className="delete"
                type="button"
                onClick={() => cart.onCartItemRemoved(item.id)}
              >
                <i className="far fa-trash-alt" />
              </button>
            </div>
          </td>
        </tr>
      )}
    </>
  );
};

export default CartItem;
