import { combineReducers } from "redux";

// reducer import
import {
  productReducer,
  topProductsReducer,
  recentProductsReducer,
  allProductsReducer,
  famousProductsReducer,
} from "./reducers/productReducer";
import { sellerReducer } from "./reducers/sellerReducer";

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  product: productReducer,
  products: allProductsReducer,
  topProducts: topProductsReducer,
  famousProducts: famousProductsReducer,
  recentProducts: recentProductsReducer,
  sellers: sellerReducer,
});

export default reducer;
