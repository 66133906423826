import React, { useState, useEffect, useCallback } from 'react'
import http from '../../../config/axios'
import { toast } from 'react-toastify'

const VerifyNumber = ({ activeStep, setActiveStep, mobile }) => {
  const [loading, setLoading] = useState(false)
  const [code, setCode] = useState('')
  const [seconds, setSeconds] = useState(45)
  const [resend, setResend] = useState(false)
  const [cnt, setCnt] = useState(0)
  let counter = 45

  const verifyCode = () => {
    setLoading(true)
    http
      .post(`/users/verifyCode`, {
        phone: mobile,
        code
      })
      .then(res => {
        setLoading(false)
        if (res.data.status === 'success') {
          setActiveStep(activeStep + 1)
        }
      })
      .catch(err => {
        setLoading(false)
        toast.error(err.response.data.message || 'Invalid Code')
      })
  }

  const TimeCounter = useCallback(() => {
    const interval = setInterval(() => {
      setSeconds(seconds => seconds - 1)
      counter--
      if (counter === 0) {
        setResend(true)
        clearInterval(interval)
      }
    }, 1000)
  }, [counter])

  useEffect(() => {
    TimeCounter()
  }, [TimeCounter])

  const onResendCode = async () => {
    if (cnt < 2) {
      try {
        await http.post(`/users/getCode`, {
          phone: `${mobile}`,
          channel: 'sms'
        })
        setSeconds(30)
        setResend(false)
        TimeCounter()
        setCnt(cnt + 1)
        toast.success('6 digit code resent to your monile')
      } catch (error) {
        toast.error('Enter Valid Mobile Number')
      }
    } else {
      toast.warn('Too many Requests!')
    }
  }

  return (
    <>
      <p className="text-center">Enter 6 digit number that was sent to {mobile}</p>
      <div className="col-12 form-group">
        <input
          type="number"
          value={code}
          onChange={e => {
            setCode(e.target.value)
          }}
          className="form-control"
          placeholder="Enter your code"
        />
      </div>

      <p className="text-center">
        {resend !== true
          ? cnt >= 1
            ? 'Maximum resends reached. Please wait to recieve your code'
            : 'To resend code please wait for '
          : 'Did not receive SMS? '}
        {cnt >= 1 ? null : (
          <button onClick={onResendCode} className="yel-btn-nr" disabled={resend !== true}>
            {resend !== true ? <span>{seconds} sec</span> : 'Resend Code'}
          </button>
        )}
      </p>
      <button
        type="button"
        onClick={verifyCode}
        className={loading ? `btn-disable form-control text-center` : `pink-btn-nr form-control text-center`}
        disabled={loading}
      >
        {loading ? 'Verifying...' : 'Verify Mobile'}
      </button>
    </>
  )
}

export default VerifyNumber
