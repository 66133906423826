// action - state management
import * as actionTypes from "../types";

export const initialState = {
  loading: true,
  sellerList: [],
  error: null,
};

// ==============================|| SELLER REDUCER ||============================== //

export const sellerReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SELLER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.SELLER_SUCCESS:
      return {
        ...state,
        loading: false,
        sellerList: action.payload,
        error: null,
      };
    case actionTypes.SELLER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
