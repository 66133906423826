import React, { useContext, useEffect, useState } from "react";
import { useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { getProductDetails } from "../../services/products";
import Price from "./Price";

const CheckoutCard = () => {
  const auth = useContext(AuthContext);

  const [selectedSize, setSelectedSize] = useState([]);
  const [total, setTotal] = useState(0);
  const [weight, setWeight] = useState(0);

  const loadCartItems = useCallback(() => {
    auth.cart.map((item) => {
      return getProductDetails(item.id).then((data) => {
        const selected = data.variants[0].sizes.find(
          (o) => o._id === item.size
        );
        selected["qty"] = item.qty;
        selected["product_title"] = data.title;
        setSelectedSize((selectedSize) => [...selectedSize, selected]);
      });
    });
  }, [auth.cart]);

  useEffect(() => {
    setSelectedSize([]);
    loadCartItems();
  }, [loadCartItems]);

  useEffect(() => {
    const cartTotal = selectedSize.reduce((total, item) => {
      return total + item.qty * item.price;
    }, 0);
    setTotal(Math.round(cartTotal));
    const cartWeight = selectedSize.reduce((weight, item) => {
      return weight + item.qty * item.weight;
    }, 0);
    setWeight(cartWeight);
  }, [selectedSize]);

  let location = useLocation();

  return (
    <div className="col-12 col-lg-4">
      <div className="cart-detail">
        <div className={"top pink-bg d-flex justify-content-between"}>
          <h2>Cart Details</h2>
        </div>
        <div className="middle">
          {selectedSize.map((item) => (
            <div
              className="media align-items-center d-sm-flex d-block d-sm-text-left"
              key={item._id}
            >
              {/* <img src="images/product-1.png" className="img-fluid" alt="" /> */}
              <div className="media-body">
                <div className="justify-content-between align-items-center d-sm-flex d-block d-sm-text-left">
                  <p>{item.product_title}</p>
                  <Price
                    option={item.weight}
                    price={item.price}
                    qty={item.qty}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="bottom">
          <div className="d-flex justify-content-between">
            <span>Weight: {weight} gms</span>
            <h5>Total:</h5>
            <h6>{total}</h6>
          </div>
          {auth.isAuthenticated ? (
            auth.cart.length > 0 ? (
              location.pathname === "/check-out" ? null : (
                <Link className="yel-btn-nr" to="/check-out" type="button">
                  Checkout
                </Link>
              )
            ) : (
              "Please add some products to cart"
            )
          ) : (
            <button
              className="yel-btn-nr"
              type="button"
              data-toggle="modal"
              data-target=".check-out-popup"
            >
              Checkout
            </button>
          )}
          <Link to="/products">Back to shop more</Link>
        </div>
      </div>
    </div>
  );
};

export default CheckoutCard;
