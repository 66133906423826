import { toast } from "react-toastify";
import http from "../config/axios";

export default async function displayRazorpay(
  user,
  amount,
  address,
  groupBySeller,
  auth,
  history,
  selectedSize
) {
  const response = await http.post("/orders/checkout", {
    amount,
  });

  const result = await response.data;

  const { id: order_id, currency } = result;

  const options = {
    key: process.env.RAZORPAY_KEY_ID,
    currency: currency,
    amount: result.amountamount,
    name: "VillageDukaan",
    description: "VillageDukaan",
    order_id: order_id,
    handler: async function (response) {
      const data = {
        orderCreationId: order_id,
        razorpayPaymentId: response.razorpay_payment_id,
        razorpayOrderId: response.razorpay_order_id,
        razorpaySignature: response.razorpay_signature,
      };

      const verify = await http.post("/orders/verify", data);

      if (verify.data.msg === "success") {
        let orders = Object.keys(groupBySeller).map(async (key) => {
          const orderItems = selectedSize.filter(
            (item) => item.seller === groupBySeller[key][0].seller
          );

          const totalPricePerSellerOrder = orderItems.reduce(
            (n, { price, qty }) => n + price * qty,
            0
          );

          const totalToPaySeller = orderItems.reduce(
            (n, { sellerPrice, qty }) => n + sellerPrice * qty,
            0
          );

          const totalTaxCollected = orderItems.reduce(
            (n, { gstCharges, qty }) => n + gstCharges * qty,
            0
          );

          const totalShippingCollected = orderItems.reduce(
            (n, { otherCosts, qty }) => n + otherCosts * qty,
            0
          );

          const totalDiscountApplied = orderItems.reduce(
            (n, { discountAmount, qty }) => n + discountAmount * qty,
            0
          );

          const totalVdCharges = orderItems.reduce(
            (n, { vdCharges, qty }) => n + vdCharges * qty,
            0
          );

          const orderInfo = {
            orderItems: orderItems,
            address: address.id,
            seller: groupBySeller[key][0].seller,
            isPaid: true,
            totalPrice: totalPricePerSellerOrder,
            totalSellerPrice: totalToPaySeller,
            taxPrice: totalTaxCollected,
            shippingPrice: totalShippingCollected,
            totalDiscount: totalDiscountApplied,
            totalVdCharges,
            paidAt: new Date(),
            paymentResult: {
              status: "paymentSuccess",
              razorpay_order_id: response.razorpay_order_id,
              razorpay_payment_id: response.razorpay_payment_id,
            },
          };
          await http.post(`/orders`, orderInfo);
        });

        Promise.all(orders)
          .then((data) => {
            if (data) {
              history.push({
                pathname: "/order-log",
              });
              auth.onClearCartItems();
              toast.success("Order placed successfully!");
            }
          })
          .catch((error) => {
            const err = JSON.stringify(error);
            const message = JSON.parse(err).message;
            toast.error(message);
          });
      } else {
        toast.error("Payment failed!");
      }
    },
    prefill: {
      name: `${user.firstName} ${user.lastName}`,
      email: user.email,
      contact: user.phone,
    },
  };

  const paymentObject = new window.Razorpay(options);

  paymentObject.on("payment.failed", function (response) {
    toast.error(response.error.description);
  });
  paymentObject.open();
}
