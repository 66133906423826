import React from "react";
import { NavLink } from "react-router-dom";

const ProductCard = ({ product }) => {
  const imagePath = `${process.env.REACT_APP_CLOUDFRONT_PUBLIC_CDN}/${product.images[0].url}`;

  return (
    <div className="col-12 col-xl-4 col-md-6">
      <div className="box disable-box">
        <NavLink to={{ pathname: `/products/${product.slug}` }}>
          <div className="restrict">
            <div className="img-cont">
              <img src={imagePath} className="img-fluid big-img" alt="" />
            </div>
          </div>
          <div className="box-middle">
            <h4 className="text-over">{product.title}</h4>
            <div
              className="mt-2"
              dangerouslySetInnerHTML={{
                __html: product.brand,
              }}
            />
            <h3 className="yel">{product.price}</h3>
          </div>
          <div className="cart-btn">
            <img
              src={`${process.env.PUBLIC_URL}/images/product-cart.png`}
              className="img-fluid"
              alt="Add to cart"
            />
          </div>
        </NavLink>
      </div>
    </div>
  );
};

export default ProductCard;
