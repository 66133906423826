import React, { Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import http from "../../config/axios";
import { Link } from "react-router-dom";
const DeliveryInformation = () => {
  const [org, setOrg] = useState();

  const loadOrg = () => {
    http
      .get("/contacts")
      .then((resp) => {
        setOrg(resp.data.data[0]);
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    loadOrg();
  }, []);
  return (
    <div className="">
      {/*inner-header-section start here*/}
      <section className="inner-header-section">
        <div className="container">
          <div className="row">
            <div className="col-xl-7 col-lg-9 col-12">
              <h1>Delivery Information</h1>
              <h4 className="pink">Discover the Unique Village Items!</h4>
            </div>
          </div>
        </div>
      </section>
      {/*inner-header-section end here*/}
      <div className="default deliver-info p-100 service-charges">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h5 className="pink">VillageDukaan Delivery :</h5>
            </div>
          </div>
          <div className="row">
            <div className="col-12 mt-4">
              {!org ? (
                <Spinner />
              ) : (
                org.deliveryInformation.map((item, key) => (
                  <Fragment key={key}>
                    <h5 className="mb-3">{item.header}</h5>
                    <p>{item.paragraph}</p>
                  </Fragment>
                ))
              )}
              <Link to="/" className="pink-btn-nr">
                Continue Shopping
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DeliveryInformation;
