import React, { useEffect, useState } from "react";
import http from "../../config/axios";
import { toast } from "react-toastify";
import Spinner from "../mics/Spinner";
import FAQ from "../includes/FAQ";

const ContactUs = () => {
  const [org, setOrg] = useState();

  const loadOrg = () => {
    http
      .get("/contacts")
      .then((resp) => {
        setOrg(resp.data.data[0]);
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    loadOrg();
  }, []);

  return (
    <>
      {!org ? (
        <Spinner />
      ) : (
        <div>
          {/*inner-header-section start here*/}
          <section className="inner-header-section">
            <div className="container">
              <div className="row">
                <div className="col-xl-7 col-lg-9 col-12">
                  <h2>Contact Us</h2>
                  <h4 className="pink mt-4">
                    Discover the unique village items!
                  </h4>
                </div>
              </div>
            </div>
          </section>
          {/*inner-header-section end here*/}
          <section className="contact-us p-100">
            <div className="container">
              <div className="row">
                <div className="col-lg-7  col-12">
                  <FAQ />
                </div>
                <div className="col-xl-4 col-lg-5 offset-xl-1 col-12">
                  <h5>Contact Information</h5>
                  <ul className="contact-info">
                    <li>
                      <div className="media align-items-center">
                        <i className="fa fa-map-marker" />
                        <div className="media-body">
                          <p>{org.address}</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="media align-items-center">
                        <i className="fa fa-envelope" />
                        <div className="media-body">
                          <p>{org.email}</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <a href={org.website}>
                        <div className="media align-items-center">
                          <i className="fa fa-globe" />
                          <div className="media-body">
                            <p>{org.website}</p>
                          </div>
                        </div>
                      </a>
                    </li>
                  </ul>
                  <ul className="social-media align-items-center">
                    <p>Follow Us</p>
                    <li>
                      <a href={org.twitter} target="_blank" rel="noreferrer">
                        <i className="fab fa-twitter" />
                      </a>
                    </li>
                    <li>
                      <a href={org.facebook} target="_blank" rel="noreferrer">
                        <i className="fab fa-facebook-f" />
                      </a>
                    </li>
                    <li>
                      <a href={org.linkedin} target="_blank" rel="noreferrer">
                        <i className="fab fa-linkedin-in" />
                      </a>
                    </li>
                    <li>
                      <a href={org.instagram} target="_blank" rel="noreferrer">
                        <i className="fab fa-instagram" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
};
export default ContactUs;
