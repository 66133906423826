const CountryCodes = [
  {
    label: "IN",
    value: "91",
  },
  {
    label: "US",
    value: "1",
  },
  {
    label: "GB",
    value: "44",
  },
];

export default CountryCodes;
