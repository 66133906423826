import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import http from "./config/axios";
import { store } from "./store";
import "react-bootstrap-typeahead/css/Typeahead.css";
const loadApp = async () => {
  const authData = {
    isAuthenticated: false,
    user: undefined,
  };

  try {
    const { data } = await http.get("/users/currentuser");

    if (data.currentUser !== null) {
      authData.isAuthenticated = true;
      authData.user = data;
    } else {
      authData.isAuthenticated = false;
      authData.user = undefined;
    }
  } catch (error) {
    authData.isAuthenticated = false;
    authData.user = undefined;
  }

  ReactDOM.render(
    <Provider store={store}>
      <App auth={authData} />
    </Provider>,
    document.getElementById("root")
  );

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister();
};

loadApp();
