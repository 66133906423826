// action - Products Reducer
export const ALL_PRODUCTS_REQUEST = "ALL_PRODUCTS_REQUEST";
export const ALL_PRODUCTS_SUCCESS = "ALL_PRODUCTS_SUCCESS";
export const ALL_PRODUCTS_FAIL = "ALL_PRODUCTS_FAIL";

export const PRODUCT_REQUEST = "PRODUCT_REQUEST";
export const PRODUCT_SUCCESS = "PRODUCT_SUCCESS";
export const PRODUCT_FAIL = "PRODUCT_FAIL";

export const TOP_PRODUCT_REQUEST = "TOP_PRODUCT_REQUEST";
export const TOP_PRODUCT_SUCCESS = "TOP_PRODUCT_SUCCESS";
export const TOP_PRODUCT_FAIL = "TOP_PRODUCT_FAIL";

export const FAMOUS_PRODUCT_REQUEST = "FAMOUS_PRODUCT_REQUEST";
export const FAMOUS_PRODUCT_SUCCESS = "FAMOUS_PRODUCT_SUCCESS";
export const FAMOUS_PRODUCT_FAIL = "FAMOUS_PRODUCT_FAIL";

export const RECENT_PRODUCT_REQUEST = "RECENT_PRODUCT_REQUEST";
export const RECENT_PRODUCT_SUCCESS = "RECENT_PRODUCT_SUCCESS";
export const RECENT_PRODUCT_FAIL = "RECENT_PRODUCT_FAIL";

// action - Seller Reducer
export const SELLER_REQUEST = "SELLER_REQUEST";
export const SELLER_SUCCESS = "SELLER_SUCCESS";
export const SELLER_FAIL = "SELLER_FAIL";
