import React, { useEffect, useRef, useState } from "react";
import ProductCard from "./mics/ProductCard";
import ProductFilters from "./mics/ProductFilters";
import ReactPaginate from "react-paginate";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllProducts } from "../store/actions/productActions";

const AllProducts = () => {
  const dispatch = useDispatch();
  const scrollTo = useRef(null);
  const [page, setPage] = useState(1);
  const [limit] = useState(10);

  const products = useSelector((state) => state.products);

  const { allProducts, loading } = products;

  const { search } = useLocation();

  const executeScroll = () => scrollTo.current.scrollIntoView();

  useEffect(() => {
    executeScroll();
    dispatch(getAllProducts(search, page, limit));
  }, [page, limit, search, dispatch]);

  const onPage = (page) => setPage(page.selected + 1);
  return (
    <div>
      {/*inner-header-section start here*/}
      <section className="inner-header-section">
        <div className="container">
          <div className="row">
            <div className="col-xl-7 col-lg-9 col-12">
              <h2>All Products</h2>
              <h4 className="pink mt-4">Discover the unique village items!</h4>
            </div>
          </div>
        </div>
      </section>
      {/*inner-header-section end here*/}
      {/*all products start here*/}
      <section className="all-products">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-3 col-md-12">
              <ProductFilters page={page} setPage={setPage} />
            </div>
            <div className="col-12 col-lg-9 col-md-12">
              <div className="product-bottom">
                <div className="row" ref={scrollTo}>
                  {!loading &&
                    allProducts.data?.map((product) => (
                      <ProductCard product={product} key={product.id} />
                    ))}
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div
                    className="dataTables_paginate paging_simple_numbers"
                    id="DataTables_Table_0_paginate"
                  >
                    <ReactPaginate
                      containerClassName="pagination justify-content-end"
                      pageClassName="paginate_button page-item"
                      pageLinkClassName="page-link"
                      activeClassName="active"
                      previousClassName="paginate_button page-item previous"
                      previousLinkClassName="page-link"
                      nextClassName="paginate_button page-item next"
                      nextLinkClassName="page-link"
                      activeLinkClassName="paginate_button page-item"
                      pageCount={allProducts.pages}
                      onPageChange={onPage}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*all products end here*/}
    </div>
  );
};

export default AllProducts;
