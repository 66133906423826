import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import http from "../../config/axios";

const Forget_Password = (props) => {
  const [email, setEmail] = useState("");
  const onSendEmail = async (e) => {
    e.preventDefault();
    const params = {
      email,
    };
    try {
      const response = await http.post("users/forgotPassword", params);
      const { message, token } = response.data;
      toast.success(`${message}`);
      props.history.push({
        pathname: `/forget_password_2/${token}`,
        state: { email: email },
      });
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  return (
    <section className="login-banner">
      <div className="container">
        <div className="row ">
          <div className="col-md-8 offset-md-2 col-12 ">
            <div className="login-main">
              <div className="login-inner forgot-1">
                <div className="right">
                  <img
                    src="images/pre-order.png"
                    className="img-fluid"
                    alt=""
                    width="100"
                  />
                  <h3 className="pink text-center">Forgot Password</h3>
                  <h6 className="text-center">
                    Enter your email to receive code to reset password
                  </h6>
                  <form onSubmit={onSendEmail}>
                    <div className="row">
                      <div className="col-12 form-group">
                        <input
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          type="email"
                          className="form-control"
                          placeholder="Enter Email Address"
                        />
                        <i className="fa fa-envelope" />{" "}
                      </div>
                    </div>
                    <button
                      type="sumit"
                      className="pink-btn-nr form-control text-center"
                    >
                      {" "}
                      Continue
                    </button>
                  </form>
                  <NavLink
                    to="/login"
                    className="back form-control text-center"
                  >
                    Back to login
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Forget_Password;
