import axios from "axios";
const token = localStorage.getItem("access_token");

const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

if (token) headers["Authorization"] = `Bearer ${token}`;

const baseURL = "/api/v1";

axios.defaults.headers.common["Authorization"] = "Bearer " + token;
axios.defaults.baseURL = baseURL;

const instance = axios.create({
  baseURL: baseURL,
  headers,
});

instance.interceptors.request.use(
  (config) => {
    document.querySelector(".spinner-container").style.display = "block";
    return config;
  },
  (error) => {
    document.querySelector(".spinner-container").style.display = "none";
    return Promise.reject(error);
  }
);

// Add a response interceptor
instance.interceptors.response.use(
  (response) => {
    document.querySelector(".spinner-container").style.display = "none";
    return response;
  },
  (error) => {
    document.querySelector(".spinner-container").style.display = "none";
    return Promise.reject(error);
  }
);

instance.setToken = (token) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  axios.defaults.baseURL = baseURL;
};

export default instance;
