import React from "react";
import Croppie from "../mics/Croppie";
import http from "../../config/axios";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { AuthContext } from "../../context/AuthContext";

class Edit_Profile extends React.Component {
  static contextType = AuthContext;
  state = {
    image: "",
    file: undefined,
    editing: false,
    profile: null,
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    password: "",
  };

  onPageLoad = async () => {
    const { data } = await http.get(`/users/currentuser`);
    this.setState({
      profile: data.currentUser,
      image: data.image,
      firstName: data.currentUser.firstName,
      lastName: data.currentUser.lastName,
      email: data.currentUser.email,
      phone: data.currentUser.phone,
    });
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    this.onPageLoad();
  }

  onImageCropped = (data) => {
    this.setState({ editing: false, image: data });
    fetch(data)
      .then((res) => res.blob())
      .then((blob) => {
        this.setState({
          file: new File([blob], "profile.png", { type: "image/png" }),
        });
      });
  };

  onImageCancel = () => {
    window.$("#croppieModal").modal("hide");
    this.setState({ editing: false });
  };

  profilePicChange = (fileChangeEvent) => {
    const file = fileChangeEvent.target.files[0];
    const fileReader = new FileReader();
    fileReader.onload = function (e) {
      this.setState({ image: e.target.result, editing: true });
    }.bind(this);
    fileReader.readAsDataURL(file);
  };

  onProfileSave = async () => {
    const { firstName, file, lastName, phone } = this.state;

    try {
      await http.patch("/users/updateMe", {
        firstName,
        file,
        lastName,
        phone,
      });

      toast.success("Profile Updated");
      this.props.history.push({
        pathname: "/profile",
      });
    } catch (error) {
      toast.error("Update failed... Please check your details");
    }
  };

  onPasswordChange = async () => {
    const { password } = this.state;
    try {
      await http.patch("/users/updateMyPassword", {
        password,
      });

      toast.success("Password Updated");
      this.props.history.push({
        pathname: "/profile",
      });
    } catch (err) {
      toast.error("Invalid credentials. Please check and try again");
    }
  };

  render() {
    const { profile, firstName, lastName, phone, email } = this.state;
    return (
      <div>
        <section className="inner-header-section">
          <div className="container">
            <div className="row">
              <div className="col-xl-7 col-lg-9 col-12">
                <h2>Profile</h2>
                <h4 className="pink mt-4">
                  Discover the unique village items!
                </h4>
              </div>
            </div>
          </div>
        </section>
        {/*inner-header-section end here*/}
        {/*profile start here*/}
        <section className="profile-main p-80 edit-profile">
          <Croppie
            src={this.state.image}
            shouldShow={this.state.editing}
            onCancel={this.onImageCancel}
            onCropped={this.onImageCropped}
          />
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h5>Profile Information</h5>
              </div>
            </div>
            <div className="profile-top">
              <div className="row">
                <div className="col-lg-9 col-12 ">
                  <div className="d-sm-flex justify-content-between align-items-center">
                    <div>
                      <div className="media align-items-center">
                        <div className="attached">
                          <img
                            src={
                              this.state.image ||
                              `https://ui-avatars.com/api/${
                                profile && profile.firstName
                              }/64/615bbf/fff/2/0.5/true/true/true`
                            }
                            className="img-fluid img-rounded"
                            alt=""
                          />
                          <button
                            type="file"
                            onClick={(e) =>
                              document.getElementById("file").click()
                            }
                            className="change-cover"
                          >
                            <div className="ca">
                              <i className="fa fa-camera" />
                            </div>
                          </button>
                          <input
                            id="file"
                            className="d-none"
                            onChange={(e) => {
                              this.profilePicChange(e);
                              e.target.value = "";
                            }}
                            accept="image/png, image/jpeg"
                            type="file"
                            name="file"
                          />
                        </div>
                        <div className="media-body">
                          <h4 className="yel">
                            {profile && profile.firstName}{" "}
                            {profile && profile.lastName}
                          </h4>
                          <p>
                            <i className="fa fa-envelope" />
                            Email: {profile && profile.email}
                          </p>
                          <p>
                            <i className="fa fa-phone fa-rotate-90" />
                            Phone: {profile && profile.phone}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <form>
              <div className="profile-bottom mt-5">
                <div className="row mt-5">
                  <div className="col-12 col-md-6">
                    <div className="row">
                      <div className="col-lg-6 col-12">
                        <label>First Name</label>
                        <input
                          type="text"
                          value={firstName}
                          onChange={(e) =>
                            this.setState({ firstName: e.target.value })
                          }
                          className="form-control"
                        />
                      </div>
                      <div className="col-lg-6 col-12">
                        <label>Last Name</label>
                        <input
                          type="text"
                          value={lastName}
                          onChange={(e) =>
                            this.setState({ lastName: e.target.value })
                          }
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-12">
                        <label>Contact</label>
                        <input
                          type="text"
                          readOnly
                          value={phone}
                          className="form-control"
                        />
                      </div>
                      <div className="col-lg-6 col-12">
                        <label>Email</label>
                        <input
                          type="text"
                          value={email}
                          onChange={(e) =>
                            this.setState({ email: e.target.value })
                          }
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="profile-bottom">
                  <div className="row">
                    <div>
                      <a
                        href="#/"
                        onClick={this.onProfileSave}
                        className="pink-btn"
                      >
                        Update Profile
                      </a>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <h6 className="yel">Security</h6>
                      <label>Password</label>
                      <input
                        type="password"
                        onChange={(e) =>
                          this.setState({ password: e.target.value })
                        }
                        className="form-control"
                        placeholder="Enter New Password"
                      />
                      <div className="d-flex justify-content-between align-items-center">
                        <button
                          onClick={this.onPasswordChange}
                          className="pink"
                        >
                          Update Password
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </section>
      </div>
    );
  }
}

export default withRouter(Edit_Profile);
