import React, { Component, createContext } from 'react'
import http from '../config/axios'

export const AuthContext = createContext()

class AuthContextProvider extends Component {
  state = {
    user: this.props.auth.user,
    isAuthenticated: this.props.auth.isAuthenticated,
    mycart: null,
    cart: [],
    country: '',
    states: ''
  }

  onGetCart = async () => {
    const { data } = await http.get('/cart')
    this.setState({ mycart: data.cart })
  }

  componentDidMount() {
    this.loadCartItems()
  }

  loadCartItems() {
    try {
      this.setState({
        cart: JSON.parse(localStorage.getItem('cart')) || []
      })
    } catch (error) {
      this.setState({
        cart: []
      })
    }
  }

  onCartItemAdded = payload => {
    const cart = this.state.cart

    const productIndex = cart.findIndex(item => item.size === payload.size)

    if (productIndex < 0) {
      cart.push(payload)
    } else {
      cart[productIndex].qty = cart[productIndex].qty + payload.qty
    }

    window.localStorage.setItem('cart', JSON.stringify(cart))

    this.setState({ cart })
  }

  onCartItemRemoved = productId => {
    const cart = this.state.cart.filter(item => item.id !== productId)
    this.setState({ cart })
    window.localStorage.setItem('cart', JSON.stringify(cart))
  }

  onClearCartItems = () => {
    this.setState({ cart: [] })
    window.localStorage.removeItem('cart')
  }

  LoggedIn = (user, isAuthenticated) => this.setState({ user, isAuthenticated })

  LoggedOut = () => this.setState({ user: undefined, isAuthenticated: false })

  AddToWishList = product => {
    const products = this.state.wishlistProducts
    products.push(product)
    this.setState({ wishlistProducts: products })
  }

  RemoveFromWishList = id => {
    const products = this.state.wishlistProducts.filter(product => product.id !== id)

    this.setState({ wishlistProducts: products })
  }

  render() {
    return (
      <AuthContext.Provider
        value={{
          ...this.state,
          onLoggedIn: this.LoggedIn,
          onLoggedOut: this.LoggedOut,
          loadWishlistProducts: this.loadWishlistProducts,
          onAddToWishlist: this.AddToWishList,
          onRemoveFromWishlist: this.RemoveFromWishList,
          onGetCart: this.onGetCart,
          onCartItemAdded: this.onCartItemAdded,
          onCartItemRemoved: this.onCartItemRemoved,
          onClearCartItems: this.onClearCartItems
        }}
      >
        {this.props.children}
      </AuthContext.Provider>
    )
  }
}

export default AuthContextProvider
