import React from "react";
import { Link } from "react-router-dom";

const CategoryCard = ({ category }) => {
  let imagePath = `${process.env.REACT_APP_CLOUDFRONT_PUBLIC_CDN}/${category.imageCover}`;

  return (
    <div className="col-6 col-lg-2 col-md-3 col-sm-4 d-flex w-100">
      <Link to={`products?isProductApproved=true&category=${category.name}`}>
        <div className="box">
          <img
            style={{ height: "100px", width: "200px" }}
            src={imagePath}
            className="img-fluid"
            alt={category.name}
          />
          <h6>{category.name}</h6>
        </div>
      </Link>
    </div>
  );
};

export default CategoryCard;
