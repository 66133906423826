import React from "react";
import { useState } from "react";

export const TextField = ({
  label,
  labelAppend,
  type = "text",
  name,
  error,
  icon,
  iconPosition = "left",
  placeholder,
  className,
  value,
  children,
  disabled,
  id,
  onChange,
  onClick,
}) => {
  const [isFocus, setFocus] = useState(false);

  const isError = error ? error.length : false;

  return (
    <>
      <div className={className}>
        {label && (
          <div>
            <label
              className={`text-sm font-medium text-gray-900 block ${
                isError ? "text-warning" : "text-gray-900"
              }`}
              htmlFor={id}
            >
              {label}
            </label>
            {labelAppend && <div className="ml-4">{labelAppend}</div>}
          </div>
        )}
        <div
          className={`${
            disabled ? "bg-gray-100" : isError ? "bg-red-50" : "bg-white"
          } ${
            isError
              ? "border-warning"
              : isFocus
              ? "border-primary"
              : "border-gray-300"
          }`}
          onClick={onClick}
        >
          <input
            className="form-control"
            type={type}
            name={name || ""}
            disabled={disabled}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            value={value || ""}
            onChange={onChange}
            placeholder={placeholder || ""}
            id={id}
          />
          {iconPosition === "right" ? icon : children}
        </div>
        {error && (
          <div
            className={
              error.length ? "block mt-2 text-warning text-sm" : "hidden"
            }
          >
            {error}
          </div>
        )}
      </div>
    </>
  );
};
