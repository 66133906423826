import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import http from "../../config/axios";
import Accordion from "../Common/Accordian";

const FAQ = () => {
  const [accordionData, setAccordianData] = useState([]);
  const loadFAQs = () => {
    http
      .get("/faqs")
      .then((resp) => {
        setAccordianData(resp.data.data);
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  useEffect(() => {
    loadFAQs();
  }, []);
  return (
    <div>
      <h3>Frequently Asked Questions (FAQ)</h3>
      <div className="accordion">
        {accordionData.map(({ title, content, id }) => (
          <Accordion title={title} content={content} key={id} />
        ))}
      </div>
    </div>
  );
};

export default FAQ;
