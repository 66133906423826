import http from "../config/axios";
import Decrypt from "../utils/Decrypt";
export async function getProductDetails(id) {
  const { data } = await http.get(`/products/${id}`);
  return Decrypt(data.data);
}

export async function getTopProducts() {
  const data = http.get(`/products?topProduct=true`);

  return data;
}
