import React from "react";

const Price = ({ option, price, qty }) => {
  let calculatedPrice = 0;
  // let currency = price.formatted.replace(/[0-9]/g, '');
  if (option) {
    calculatedPrice += parseFloat(price);
  }

  return <h4>{Math.round(calculatedPrice * qty)}</h4>;
};

export default Price;
