import React from "react";
import Home from "./components/Home";
import AllProducts from "./components/AllProducts";
import Category from "./components/Category";
import Login from "./components/authentications/Login";
import Register from "./components/authentications/Register/index";
import Profile from "./components/AfterAuth/Profile";
import OrderLog from "./components/AfterAuth/Order_Log";
import EditProfile from "./components/AfterAuth/Edit_Profile";
import ForgetPassword from "./components/authentications/Forget_Password";
import ForgetPassword2 from "./components/authentications/Forget_Password_2";
import ForgetPassword3 from "./components/authentications/Forget_Password_3";
import MyCart from "./components/MyCart";
import { BrowserRouter } from "react-router-dom";
import PrivareRoute from "./PrivateRoutes";
import PublicRoute from "./PublicRoute";
import AuthContextProvider from "./context/AuthContext";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.min.css";
import { ToastContainer } from "react-toastify";
import Checkout from "./components/checkout/Checkout";
import OrderDetails from "./components/AfterAuth/Order_Details";
import ContactUs from "./components/AfterAuth/Contactus";
import TermsOfUsingWebsite from "./components/includes/TermsOfUsingWebsite";
import AboutUs from "./components/includes/AboutUs";
import YourSecurity from "./components/includes/YourSecurity";
import CookiePolicy from "./components/includes/CookiePolicy";
import ServiceChargesPolicy from "./components/includes/ServiceChargesPolicy";
import DeliveryInformation from "./components/includes/DeliveryInformation";
import ReturnPolicy from "./components/includes/ReturnPolicy";
import Store from "./components/includes/Store";
import Order_Tracking from "./components/AfterAuth/Order_Tracking";
import ProductDetails from "./components/ProductDetails";

import "./App.css";
import DeleteAccount from "./components/AfterAuth/Delete_Account";

const contextClass = {
  success: "bg-blue-600",
  error: "bg-red-600",
  info: "bg-gray-600",
  warning: "bg-orange-400",
  default: "bg-indigo-600",
  dark: "bg-white-600 font-gray-300",
};

const App = ({ auth }) => {
  return (
    <>
      <AuthContextProvider auth={auth}>
        {/* <Chat /> */}
        <BrowserRouter>
          <ToastContainer
            position="top-center"
            autoClose={4000}
            hideProgressBar={true}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <PublicRoute exact path="/my-cart" component={MyCart} />
          <PrivareRoute exact path="/check-out" component={Checkout} />
          <PublicRoute exact path="/products" component={AllProducts} />
          <PublicRoute path="/products/:id" component={ProductDetails} />
          <PublicRoute path="/categories" component={Category} />
          <PrivareRoute exact path="/order-log" component={OrderLog} />
          <PrivareRoute path="/order-detail/:id" component={OrderDetails} />
          <PrivareRoute path="/track-order/:id" component={Order_Tracking} />
          <PrivareRoute exact path="/profile" component={Profile} />
          <PrivareRoute path="/profile/edit" component={EditProfile} />
          <PrivareRoute path="/profile/delete" component={DeleteAccount} />

          <PublicRoute
            exact
            path="/terms-of-using-website"
            component={TermsOfUsingWebsite}
          />
          <PublicRoute exact path="/about-us" component={AboutUs} />
          <PublicRoute exact path="/your-security" component={YourSecurity} />
          <PublicRoute exact path="/cookie-policy" component={CookiePolicy} />
          <PublicRoute
            exact
            path="/servive-charges-policy"
            component={ServiceChargesPolicy}
          />
          <PublicRoute exact path="/return-policy" component={ReturnPolicy} />
          <PublicRoute exact path="/store" component={Store} />
          <PublicRoute
            exact
            path="/delivery-information"
            component={DeliveryInformation}
          />
          <PublicRoute exact path="/login" component={Login} />
          <PublicRoute exact path="/contact" component={ContactUs} />
          <PublicRoute exact path="/register" component={Register} />
          <PublicRoute
            exact
            path="/forget_password"
            component={ForgetPassword}
          />
          <PublicRoute
            path="/forget_password_2/:token"
            component={ForgetPassword2}
          />
          <PublicRoute
            path="/forget_password_3/:token"
            component={ForgetPassword3}
          />
          <PublicRoute exact path="/" component={Home} />
        </BrowserRouter>
      </AuthContextProvider>
    </>
  );
};

export default App;
