import React, { useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import http from "../../config/axios";
import { AsyncTypeahead } from "react-bootstrap-typeahead";

const SearchBox = () => {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const [term, setTerm] = useState("");
  const [page] = useState(1);

  const onSearch = (query) => {
    setIsLoading(true);
    http
      .get(
        `/products?isProductApproved=true&fields=title,category,images,slug&keyword=${query}&page=${page}&limit=10`
      )
      .then((resp) => {
        setOptions(resp.data.data);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (term) {
      term.find((item) => history.push({ pathname: `/products/${item.slug}` }));
      setOpen(false);
    }
  }, [term, history]);

  const filterBy = () => true;
  return (
    <li className={open ? "search-main search-open" : "search-main"}>
      <div className="main-header__searchbar focus">
        <div className="main-header__searchbar__curtain main-header__searchbar__curtain--1" />
        <div className="main-header__searchbar__cont main-header__searchbar__curtain main-header__searchbar__curtain--2">
          <NavLink className="nav-brand d-none d-sm-block" to="/">
            <img
              src="images/logo.png"
              alt="logo"
              height={60}
              className="mt-4 ml-5"
            />
          </NavLink>
          <div className="main-header__searchbar__input">
            <AsyncTypeahead
              id="search-async"
              filterBy={filterBy}
              isLoading={isLoading}
              labelKey="title"
              minLength={1}
              onSearch={onSearch}
              options={options}
              placeholder="Search products..."
              onChange={setTerm}
              autoFocus
              emptyLabel="No matching products found"
              renderMenuItemChildren={(option) => (
                <>
                  <ul className="list-inline">
                    <li className="list-inline-item">
                      {" "}
                      <img
                        alt={option.id}
                        src={`${process.env.REACT_APP_CLOUDFRONT_PUBLIC_CDN}/${option.images[0].url}`}
                        style={{
                          height: "50px",
                          marginRight: "10px",
                          width: "50px",
                        }}
                      />
                    </li>
                    <li className="list-inline-item">
                      <figure>
                        <blockquote class="blockquote">
                          {option.title}
                        </blockquote>
                      </figure>
                    </li>
                    <li className="list-inline-item">
                      <figure>
                        <figcaption class="blockquote-footer">
                          Category:{" "}
                          <cite title="Category Title">{option.category}</cite>
                        </figcaption>
                      </figure>
                    </li>
                  </ul>
                </>
              )}
            />
            <input type="submit" defaultValue="Submit" className="hidden" />
            <div
              onClick={(e) => setOpen(false)}
              className="main-header__searchbar__close"
            >
              <img
                src={`${process.env.PUBLIC_URL}/images/close-icon.png`}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div
        onClick={(e) => setOpen(true)}
        className="main-header__search__toggle wow slideInDown"
      >
        <span className="icon-search">
          <i className="fas fa-search" />
        </span>
      </div>
    </li>
  );
};

export default SearchBox;
