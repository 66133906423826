const StatesInIndia = [
  { value: "Andhra Pradesh" },
  { value: "Arunachal Pradesh" },
  { value: "Assam" },
  { value: "Bihar" },
  { value: "Chhattisgarh" },
  { value: "Chandigarh (UT)" },
  { value: "Goa" },
  { value: "Delhi (UT)" },
  { value: "Gujarat" },
  { value: "Haryana" },
  { value: "Himachal Pradesh" },
  { value: "Jammu and Kashmir (UT)" },
  { value: "Jharkhand" },
  { value: "Karnataka" },
  { value: "Kerala" },
  { value: "Lakshadweep (UT)" },
  { value: "Madhya Pradesh" },
  { value: "Maharashtra" },
  { value: "Manipur" },
  { value: "Meghalaya" },
  { value: "Mizoram" },
  { value: "Nagaland" },
  { value: "Odisha" },
  { value: "Puducherry (UT)" },
  { value: "Punjab" },
  { value: "Sikkim" },
  { value: "Tamil Nadu" },
  { value: "Telangana" },
  { value: "Tripura" },
  { value: "Uttarakhand" },
  { value: "Uttar Pradesh" },
  { value: "West Bengal" },
];

export default StatesInIndia;
