import React, { Fragment, useEffect } from "react";
import ProductCard from "./mics/HomeProductCard";
import { Link } from "react-router-dom";
import ProductCarousel from "./includes/ProductCorousel";
import { useDispatch, useSelector } from "react-redux";
import {
  getFamousProducts,
  getRecentProducts,
} from "../store/actions/productActions";

var scroll = React.createRef();

const Home = (props) => {
  const dispatch = useDispatch();
  const productsRecentlyAdded = useSelector((state) => state.recentProducts);
  const { recentProductList } = productsRecentlyAdded;

  const { famousProductsList } = useSelector((state) => state.famousProducts);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (recentProductList.length === 0) {
      dispatch(getRecentProducts());
    }
    if (famousProductsList.length === 0) {
      dispatch(getFamousProducts());
    }
  }, [dispatch, recentProductList, famousProductsList]);

  return (
    <Fragment>
      <section className="header-section">
        <ProductCarousel />
        <div className="right">
          <img src="images/header-4.png" className="img-fluid img-4" alt="" />
          <img src="images/header-5.png" className="img-fluid img-5" alt="" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-xl-7 col-lg-9 col-12">
              <button
                id="animates"
                onClick={() =>
                  document.querySelector(".all-need").scrollIntoView()
                }
                className="black-btn"
              >
                <i className="fa fa-arrow-down" />
              </button>
            </div>
          </div>
        </div>
      </section>
      {/*all-need start here*/}
      <section ref={scroll} className="all-need">
        <div className="container">
          <div className="top">
            <div className="row">
              <div className="col-lg-6 col-12">
                <div className="left">
                  <h2>
                    All You Need to Know About{" "}
                    <span className="pink">VillageDukaan</span>
                  </h2>
                </div>
              </div>
              <div className="col-lg-6 col-12 ">
                <div className="right">
                  <p>
                    VillageDukaan which is trading under Vaishnavi VillageDukaan
                    E-Commerce Private Limited is a startup opened in November
                    2017. We started selling products at the company address
                    from 2nd May 2018 and the website is going live now to our
                    customers in and around Rajahmundry.{" "}
                  </p>
                  <Link to="/about-us" className="pink-btn">
                    read more
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="bottom">
            <ul className="d-md-flex w-100 ">
              <li className="box white-box d-flex w-100 boxer">
                <div className="media align-items-center">
                  <img src="images/all-1.png" className="img-fluid" alt="" />
                  <div className="media-body">
                    <h4>
                      Mon - Sun <span>9:00AM - 9:00PM</span>
                    </h4>
                    <p>Support Days/Hours!</p>
                  </div>
                </div>
              </li>
              <li className="box yel-bg d-flex w-100 boxer">
                <div className="media align-items-center">
                  {" "}
                  <img src="images/all-2.png" className="img-fluid" alt="" />
                  <div className="media-body">
                    <h4>Become a Seller</h4>
                    <p>Ship your products on VD!</p>
                  </div>
                </div>
              </li>
              <li className="box pink-bg d-flex w-100 boxer">
                <div className="media align-items-center">
                  {" "}
                  <img src="images/all-3.png" className="img-fluid" alt="" />
                  <div className="media-body">
                    <h4>Village Food</h4>
                    <p>No Minimum Order Value!</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
      {/*all-need end here*/}
      {/*our product start here*/}
      <section className="our-product">
        <div className="container">
          <div className="product-top d-md-flex align-items-center justify-content-md-between">
            <div className="left">
              <h2>
                See <br />
                Our Products
              </h2>
            </div>
            <div className="right">
              <Link to="/products" className="yel-btn">
                View All Products
              </Link>
            </div>
          </div>
          <div className="product-bottom">
            <div className="row">
              {recentProductList.map((product) => (
                <ProductCard product={product} key={product.id} />
              ))}
            </div>
          </div>
        </div>
      </section>
      {/*our product end here*/}
      {/*village restaurant start here*/}
      {/* <section className="village-res ">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-12">
              <div className="left boxer">
                <div className="img-res">
                  <img
                    src="images/res-bg.png"
                    className="w-100 h-auto"
                    alt=""
                  />
                </div>
                <img src="images/res-1.png" className="img-fluid" alt="" />
                <h1>
                  Village <br />
                  Restaurant
                </h1>
                <p>We Deliver Village Food &amp; Drink</p>

                <Link to="/products?category=pickles" className="yel-btn">
                  Order Now
                </Link>
              </div>
            </div>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => props.history.push("/products?category=pickles")}
              className="col-lg-4 col-12"
            >
              <div className="right">
                <h2>
                  Hand <span className="text-1">Made </span>
                  <span className="text-2">Pickles</span>
                </h2>
                <img
                  src="images/res-2.png"
                  className="img-fluid"
                  alt="bottle"
                />
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/*village restaurant end here*/}
      {/*village app start here*/}
      {/* <section className="village-app">
        <div className="container">
          <div className="row ">
            <div className="col-12 col-lg-5 position-relative">
              <div className="left">
                <img
                  src="images/app-img-1.png"
                  className="img-fluid img-1 wow fadeInLeft"
                  data-wow-duration="1.3s"
                  data-wow-delay="1.1s"
                  alt=""
                />
                <img
                  src="images/app-img-2.png"
                  className="img-fluid img-2 wow fadeInUp"
                  data-wow-duration=".9s"
                  data-wow-delay=".7s"
                  alt=""
                />
                <img
                  src="images/app-img-3.png"
                  className="img-fluid img-3 wow fadeInRight"
                  data-wow-duration="1.5s"
                  data-wow-delay="1.3s"
                  alt=""
                />
              </div>
            </div>
            <div className="col-12 col-lg-7">
              <div className="right">
                <h1
                  className="wow fadeInUp"
                  data-wow-duration=".5s"
                  data-wow-delay=".5s"
                >
                  Download Village <br />
                  Dukaan App Today
                </h1>
                <div className="bottom">
                  <a
                    href="https://apps.apple.com/in/app/villagedukaan/id1442479463"
                    className="wow fadeInUp "
                    data-wow-duration="1.1s"
                    data-wow-delay=".8s"
                  >
                    <img src="images/app.png" className="img-fluid" alt="" />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.villagedukaan.villagedukaanandroidapp&hl=en"
                    className="wow fadeInUp "
                    data-wow-duration="1.4s"
                    data-wow-delay="1s"
                  >
                    <img src="images/play.png" className="img-fluid" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/*village app end here*/}
      {/*daal start here*/}
      <section className="daal">
        <div className="container">
          <div className="daal-inner yel-bg">
            <div className="row">
              <div className="col-12" style={{ cursor: "pointer" }}>
                <div className="d-md-flex justify-content-between align-items-center">
                  <div>
                    <h1
                      className="wow fadeInUp "
                      data-wow-duration=".5s"
                      data-wow-delay=".5s"
                    >
                      Our
                      <br /> Famous Products
                    </h1>
                    <Link
                      to="/products?category=dal"
                      className="pink-btn wow fadeInUp "
                      data-wow-duration=".8s"
                      data-wow-delay=".6s"
                    >
                      Order Now
                    </Link>
                  </div>
                  <div>
                    <ul>
                      {famousProductsList?.map((item, index) => (
                        <li
                          key={index}
                          onClick={() =>
                            props.history.push(`products/${item?.slug}`)
                          }
                          className="wow fadeInUp "
                          data-wow-duration="1.1s"
                          data-wow-delay=".8s"
                        >
                          <i className="fa fa-check-circle pink" />
                          {item?.title}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div
                  className="wow fadeInUp daal-img"
                  data-wow-duration="1s"
                  data-wow-delay=".8s"
                >
                  <img
                    src="images/daal.png"
                    className="img-fluid rotating"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*daal end here*/}
    </Fragment>
  );
};

export default Home;
