import React, { useState, useEffect } from 'react';

const ProductImages = ({ images }) => {
	const [previewImage, setPreviewImage] = useState('');

	useEffect(() => {
		if (images.length === 0) return;

		setPreviewImage(images[0].url);
	}, [images]);

	return (
		<div className='row'>
			<div className='col-md-4 col-lg-2 col-12'>
				<ul className='min-img'>
					{images.map((file, index) => (
						<li onClick={(e) => setPreviewImage(file.url)} key={index}>
							<img
								src={`${process.env.REACT_APP_CLOUDFRONT_PUBLIC_CDN}/${file.url}`}
								className='img-fluid'
								alt={file.id}
							/>
						</li>
					))}
				</ul>
			</div>
			<div className='col-lg-10 col-md-8 col-12'>
				<div className='max-img'>
					<img
						src={`${process.env.REACT_APP_CLOUDFRONT_PUBLIC_CDN}/${previewImage}`}
						className='img-fluid big-img'
						alt=''
					/>
				</div>
			</div>
		</div>
	);
};

export default ProductImages;
