import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Carousel } from "react-bootstrap";
import Spinner from "../mics/Spinner";
import { toast } from "react-toastify";
import http from "../../config/axios";

const imageName = require("../images/inner-header.png");

const ProductCarousel = () => {
  const [highlights, setHighlights] = useState([]);

  const loadHighlight = () => {
    http
      .get("/contacts")
      .then((resp) => {
        setHighlights(resp.data.data[0].highlights);
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    loadHighlight();
  }, []);

  return highlights.length === 0 ? (
    <Spinner />
  ) : (
    <div className="carousel-div">
      <Carousel
        controls={true}
        prevLabel=""
        nextLabel=""
        fade
        slide={false}
        className="bg-light"
      >
        {highlights.map((highlight) => (
          <Carousel.Item key={highlight.title}>
            <div
              className="item height-9 sm-height-600px"
              data-slide_theme="light-slide"
            >
              <div
                className="background-image"
                data-background={`url(${imageName})`}
                data-bg-posx="center"
                data-bg-posy="top"
                data-bg-overlay="0"
              ></div>
              <div className="container">
                <div className="row">
                  <div className="col-lg-8">
                    <div className="intro-caption intro-caption-middel text-left p-5">
                      <p>{highlight.hashTag}</p>
                      <h1 className="mb-4">{highlight.title}</h1>
                      <h3>{highlight.subTitle}</h3>
                    </div>
                  </div>
                  <div className="col-lg-4 d-flex justify-content-center align-self-center mb-lg-0 mb-5">
                    <Link to={highlight.productLink} className="yel-btn">
                      Shop Now
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

export default ProductCarousel;
