import React, { Fragment, useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import http from "../../config/axios";
import Decrypt from "../../utils/Decrypt";

const Order_Tracking = (props) => {
  const [tracking, setTracking] = useState(null);
  const [order, setOrder] = useState({});
  const { id } = useParams();

  const status = {
    order_placed: "Order Placed",
    order_dispatch_estimate: "Order Dispatch Estimate",
    order_shipped: "Order Shipped",
    order_delivered: "Order Delivered",
    order_cancelled: "order_cancelled",
  };
  const state = {
    order_placed: 1,
    order_dispatch_estimate: 2,
    order_shipped: 3,
    order_delivered: 4,
    order_cancelled: 5,
  };

  const onViewOrder = useCallback(async () => {
    const { data } = await http.get(
      `/orders/${id}?fields=slug,orderStatus,createdAt,seller,dispatchEstimate,shippedMessage,deliveryInstructions`
    );
    setOrder(Decrypt(data.data));
  }, [id]);

  const onTrackOrder = useCallback(async () => {
    if (
      order.orderStatus === "order_shipped" ||
      order.orderStatus === "order_delivered"
    ) {
      const { data } = await http.post(`/orders/tracking`, { order_id: id });
      setTracking(data.data);
    }
  }, [id, order.orderStatus]);

  useEffect(() => {
    window.scrollTo(0, 0);
    onViewOrder();
    if (!tracking) {
      onTrackOrder();
    }
  }, [onTrackOrder, onViewOrder, tracking]);

  return (
    <>
      <section className="inner-header-section">
        <div className="container">
          <div className="row">
            <div className="col-xl-7 col-lg-9 col-12">
              <h2>Order Status</h2>
              <h4 className="pink mt-4">Discover the unique village items!</h4>
            </div>
          </div>
        </div>
      </section>
      <section className="gradient-custom-5">
        <div className="container py-5">
          <div className="order-detail">
            <div className="row">
              <div className="col-12">
                <h3>Order Status</h3>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-4 col-lg-3">
                <div className="box pink-bg">
                  <div className="media alig-items-center">
                    <div className="media-body">
                      <p>Order Number</p>
                      <h6>#{order.slug}</h6>
                    </div>
                    <i className="fas fa-hashtag" />
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4 col-lg-3">
                <div className="box yel-bg">
                  <div className="media alig-items-center">
                    <div className="media-body">
                      <p>Order Date</p>
                      <h6>
                        {new Date(order.createdAt).toDateString(undefined, {
                          timeZone: "Asia/Kolkata",
                        })}
                      </h6>
                    </div>
                    <i className="fa fa-calendar-alt" />
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4 col-lg-3">
                <div className="box white-bg">
                  <div className="media alig-items-center">
                    <div className="media-body">
                      <p>Status</p>
                      <h6>{status[order.orderStatus]}</h6>
                    </div>
                    <i className="fa fa-rocket" />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 mt-5">
                <div className="main-timeline-5">
                  <div className="timeline-5 right-5">
                    <div className="card">
                      <div className="card-body p-4">
                        <h5>Order Placed</h5>
                        <p className="mt-2 mb-0">
                          We Received your order! Order placed on{" "}
                          <span class="font-weight-bold">
                            {new Date(order.createdAt).toDateString(undefined, {
                              timeZone: "Asia/Kolkata",
                            })}{" "}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    state[order.orderStatus] > 1
                      ? "main-timeline-5"
                      : "main-timeline-6"
                  }
                >
                  <div
                    className={
                      state[order.orderStatus] > 1
                        ? "timeline-5 right-5"
                        : "timeline-6 right-5"
                    }
                  >
                    <div className="card">
                      <div className="card-body p-4">
                        {state[order.orderStatus] > 1 ? (
                          <>
                            <h5>Order Received by Seller</h5>
                            <p className="mt-2 mb-0">
                              Estimated Dispatch Date: {order.dispatchEstimate}
                            </p>
                          </>
                        ) : (
                          <>
                            <h5>Order Sent to Seller</h5>
                            <p className="mt-2 mb-0">
                              We are waiting for Order Disptach Estimate from
                              Seller
                            </p>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    state[order.orderStatus] > 2
                      ? "main-timeline-5"
                      : "main-timeline-6"
                  }
                >
                  <div
                    className={
                      state[order.orderStatus] > 2
                        ? "timeline-5 right-5"
                        : "timeline-6 right-5"
                    }
                  >
                    <div className="card">
                      <div className="card-body p-4">
                        {state[order.orderStatus] > 2 ? (
                          <>
                            <h5>Order Shipped By Seller</h5>
                            <h6 className="mt-2 mb-0">
                              Shipped from: {tracking?.from} on{" "}
                              {tracking?.pickup_date}
                            </h6>
                            <h6 className="mt-2 mb-0">
                              Shipped To: {tracking?.to}
                            </h6>
                            <p className="mt-2 mb-0">
                              {order.deliveryInstructions}
                            </p>
                          </>
                        ) : (
                          <>
                            <h5>Awaiting Delivery Details</h5>
                            <p className="mt-2 mb-0">
                              Delivery details will be shown once dispatched by
                              Seller
                            </p>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {state[order.orderStatus] > 2
                  ? []
                      .concat(tracking?.scan)
                      .reverse()
                      .map((loc, index) => (
                        <div className="main-timeline-5" key={index}>
                          <div className="timeline-5 right-5">
                            <div className="card">
                              <div className="card-body p-4">
                                <h5>{loc?.status_detail}</h5>
                                <h6 className="mt-2 mb-0">{loc?.location}</h6>
                                <p className="mt-2 mb-0">Date: {loc?.time}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                  : null}
                <div
                  className={
                    state[order.orderStatus] === 4
                      ? "main-timeline-5"
                      : "main-timeline-6"
                  }
                >
                  <div
                    className={
                      state[order.orderStatus] === 4
                        ? "timeline-5 right-5"
                        : "timeline-6 right-5"
                    }
                  >
                    <div className="card">
                      <div className="card-body p-4">
                        {state[order.orderStatus] === 4 ? (
                          <>
                            <h5>Delivered Successfully</h5>
                            <p className="mt-2 mb-0">
                              Please leave feedback to seller
                            </p>
                          </>
                        ) : (
                          <>
                            <h5>Order Delivered</h5>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Order_Tracking;
