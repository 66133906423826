import React from "react";
import http from "../../config/axios";
import useAuth from "../../hooks/useAuth";
import { toast } from "react-toastify";

export const DeleteAccount = () => {
  const { onLoggedOut } = useAuth();
  const deleteMyAccount = async () => {
    try {
      await http.delete(`/users/deleteMe`);
      await onLoggedOut();
      toast.success("Account deleted successfully");
    } catch (error) {
      toast.error("Something went wrong");
    }
  };
  return (
    <div>
      <section className="inner-header-section">
        <div className="container">
          <div className="row">
            <div className="col-xl-7 col-lg-9 col-12">
              <h2>Delete Profile</h2>
              <h4 className="pink mt-4">Discover the unique village items!</h4>
            </div>
          </div>
        </div>
      </section>
      <section className="profile-main p-80 edit-profile">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h5>Click below button to delete your account</h5>
            </div>
          </div>
          <div className="profile-bottom">
            <div className="row">
              <div className="col-12">
                <a
                  href="#/"
                  onClick={() => deleteMyAccount()}
                  className="pink-btn"
                >
                  Delete My Account
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default DeleteAccount;
