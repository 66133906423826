// action - state management
import * as actionTypes from "../types";

export const initialState = {
  loading: true,
  product: {},
  allProducts: [],
  topProductsList: [],
  famousProductsList: [],
  recentProductList: [],
  error: null,
};

// ==============================|| SELLER REDUCER ||============================== //
export const allProductsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ALL_PRODUCTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.ALL_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        allProducts: action.payload,
        error: null,
      };
    case actionTypes.ALL_PRODUCTS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        product: action.payload,
        error: null,
      };
    case actionTypes.PRODUCT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const topProductsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TOP_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.TOP_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        topProductsList: action.payload,
        error: null,
      };
    case actionTypes.TOP_PRODUCT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const famousProductsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FAMOUS_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.FAMOUS_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        famousProductsList: action.payload,
        error: null,
      };
    case actionTypes.FAMOUS_PRODUCT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const recentProductsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.RECENT_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.RECENT_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        recentProductList: action.payload,
        error: null,
      };
    case actionTypes.RECENT_PRODUCT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
