import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import http from "../../config/axios";
import { Countries } from "../../utils/country";
import StatesInIndia from "../../utils/states_in_india";
import { TextField } from "../Common/Textfield";
import CheckoutCard from "../mics/CheckoutCard";
import SelectAddress from "./SelectAddress";

const AddressForm = ({
  activeStep,
  setActiveStep,
  setDeliveryAddress,
  user,
}) => {
  const { handleSubmit } = useForm();
  const [addresses, setAddresses] = useState([]);
  const [load, setLoad] = useState(false);

  const [field, setField] = useState({
    country: "India",
    fullName: "",
    mobileNumber: user?.currentUser.phone,
    email: user?.currentUser.email,
    pincode: "",
    address: "",
    area: "",
    landmark: "",
    town: "",
    state: "",
    defaultAddress: false,
    addressType: "Home",
  });

  const setFieldByKey = (event) => {
    const value = event.target.value;
    setField({ ...field, [event.target.name]: value });
  };

  const loadAddresses = async () => {
    try {
      const { data } = await http.get(`/addresses`);
      setAddresses(data.data);
      setLoad(false);
    } catch (error) {
      toast.warn(error.message);
    }
  };

  const deleteAddress = async (e) => {
    const id = e.target.getAttribute("id");
    try {
      await http.delete(`/addresses/${id}`);
      setLoad(true);
      toast.success("Address deleted successfully");
    } catch (error) {
      toast.warn(error.message);
    }
  };

  const addAddress = async () => {
    try {
      await http.post(`/addresses`, field);
      setLoad(true);
      setField({
        country: "India",
        fullName: "",
        mobileNumber: user?.currentUser.phone,
        email: user?.currentUser.email,
        pincode: "",
        address: "",
        area: "",
        landmark: "",
        town: "",
        state: "",
        defaultAddress: false,
        addressType: "Home",
      });
      toast.success("Address added successfully");
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const chooseDeliveryAddress = (e) => {
    const id = e.target.getAttribute("id");
    try {
      setDeliveryAddress(id);
      setActiveStep(activeStep + 1);
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    loadAddresses();
  }, [load]);
  return (
    <>
      <div className="tab">
        <div className="row">
          <div className="col-12">
            {addAddress && addresses.length === 0 ? (
              <h3>Add Delivery Address</h3>
            ) : (
              <h3>Add/Choose a Delivery Address</h3>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-8 col-md-12">
            <div className="product-bottom">
              <div className="row">
                {addresses &&
                  addresses.map((address) => (
                    <SelectAddress
                      address={address}
                      key={address.id}
                      deleteAddress={deleteAddress}
                      chooseDeliveryAddress={chooseDeliveryAddress}
                      requireAddressChange={false}
                    />
                  ))}
              </div>
            </div>
          </div>
          {addAddress && addresses.length > 0 ? (
            <CheckoutCard showEdit={false} />
          ) : (
            ""
          )}
        </div>
        <div className="row">
          <div className="col-12 col-lg-8">
            <div className="left">
              <div className="billing">
                <form onSubmit={handleSubmit(addAddress)}>
                  <div className="row">
                    <div className="col-12">
                      <h6 className="pink">Add A New Address</h6>
                    </div>
                    <TextField
                      label="Full name"
                      value={field.fullName}
                      className="col-12 col-md-6 form-group"
                      onChange={(event) => setFieldByKey(event)}
                      id="fullName"
                      name="fullName"
                    />
                    <TextField
                      label="Phone number"
                      value={`${field.mobileNumber}`}
                      className="col-12 col-md-6 form-group"
                      onChange={(event) => setFieldByKey(event)}
                      id="mobileNumber"
                      name="mobileNumber"
                    />
                    <TextField
                      label="Email (Order Updates will be sent to this email)"
                      value={field.email}
                      type="email"
                      className="col-12 form-group"
                      onChange={(event) => setFieldByKey(event)}
                      id="email"
                      name="email"
                    />
                    <div className="col-12 form-group">
                      <label>Country</label>
                      <select
                        value={field.country}
                        className="form-control"
                        onChange={(event) => setFieldByKey(event)}
                        id="country"
                        name="country"
                      >
                        {Countries.map((country) => (
                          <option value={country.value} key={country.value}>
                            {country.value}
                          </option>
                        ))}
                      </select>
                    </div>
                    <TextField
                      label="Pincode"
                      value={field.pincode}
                      className="col-12 form-group"
                      onChange={(event) => setFieldByKey(event)}
                      id="pincode"
                      name="pincode"
                    />
                    <TextField
                      label="Flat or House no./ Building or Company or Apartment Name"
                      value={field.address}
                      className="col-12 form-group"
                      onChange={(event) => setFieldByKey(event)}
                      id="address"
                      name="address"
                    />
                    <TextField
                      label="Area/Street/Sector/Village"
                      value={field.area}
                      className="col-12 form-group"
                      onChange={(event) => setFieldByKey(event)}
                      id="area"
                      name="area"
                    />
                    <TextField
                      label="Landmark"
                      value={field.landmark}
                      className="col-12 form-group"
                      onChange={(event) => setFieldByKey(event)}
                      id="landmark"
                      name="landmark"
                    />
                    <TextField
                      label="City/Town/Village"
                      value={field.town}
                      className="col-12 col-md-6 form-group"
                      onChange={(event) => setFieldByKey(event)}
                      id="town"
                      name="town"
                    />
                    <div className="col-12 col-md-6 form-group">
                      <label>State</label>
                      <select
                        value={field.state}
                        className="form-control"
                        onChange={(event) => setFieldByKey(event)}
                        id="state"
                        name="state"
                      >
                        <option value="" disabled>
                          --Please choose a state--
                        </option>
                        {StatesInIndia.map((state) => (
                          <option value={state.value} key={state.value}>
                            {state.value}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="media align-items-center">
                      <div className="media-body">
                        <button className="pink-btn" type="submit">
                          Add delivery address
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          {addAddress && addresses.length === 0 ? (
            <CheckoutCard showEdit={false} />
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default AddressForm;
