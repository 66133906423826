import http from "../../config/axios";
import { SELLER_FAIL, SELLER_REQUEST, SELLER_SUCCESS } from "../types";

export const getSellers = () => async (dispatch) => {
  try {
    dispatch({
      type: SELLER_REQUEST,
    });

    const { data } = await http.get(
      "/sellers?fields=businessName,logo,user={email}"
    );

    dispatch({
      type: SELLER_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: SELLER_FAIL,
      payload: error.response.data,
    });
  }
};
