import React from "react";
import { NavLink } from "react-router-dom";

const HomeProductCard = ({ product, home }) => {
  const imagePath = product.images[0].url;

  const isHome = home === undefined ? true : false;
  return (
    <div className={isHome ? "col-12 col-lg-4 col-md-6" : "item"}>
      <div
        className="box wow fadeInUp"
        data-wow-duration="1s"
        data-wow-delay=".7s"
      >
        <NavLink to={{ pathname: `/products/${product.slug}` }}>
          <div className="box-top">
            <div className="img-cont">
              <img
                src={`${process.env.REACT_APP_CLOUDFRONT_PUBLIC_CDN}/${imagePath}`}
                className="img-fluid"
                alt={product.slug}
              />
            </div>
          </div>
          <div className="box-middle">
            <h4 className="text-over">{product.title}</h4>
          </div>
          <div className="cart-btn">
            <img
              src={`${process.env.PUBLIC_URL}/images/product-cart.png`}
              className="img-fluid"
              alt="Add to card"
            />
          </div>
        </NavLink>
      </div>
    </div>
  );
};

export default HomeProductCard;
