import React from "react";

const SelectAddress = ({
  address,
  deleteAddress,
  chooseDeliveryAddress,
  requireAddressChange,
  changeDeliveryAddress,
}) => {
  return (
    <div className="col-lg-6 col-md-6 col-12">
      <div className="customer-box">
        {requireAddressChange ? <h4>Delivery Address</h4> : ""}
        <h5 className="mb-3">
          {address.fullName} - {address.mobileNumber} - {address.email}
        </h5>
        <h6>
          {address.address}, {address.area}, {address.town}, {address.pincode},{" "}
          {address.state}, {address.country}
        </h6>
        {!requireAddressChange ? (
          <>
            <div className="media align-items-center">
              <div className="media-body">
                <button
                  className="pink-btn"
                  id={address.id}
                  onClick={chooseDeliveryAddress}
                >
                  Deliver to this address
                </button>
              </div>
            </div>
            <div className="media align-items-center">
              <button className="text-normal">Edit</button>
              <button
                className="text-normal text-danger"
                onClick={deleteAddress}
                id={address.id}
              >
                Delete
              </button>
            </div>
          </>
        ) : (
          <div className="media align-items-center">
            <div className="media-body">
              <button className="text-normal" onClick={changeDeliveryAddress}>
                Change Delivery Address
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectAddress;
