import React, { useState, useContext, useEffect } from "react";
import { NavLink, withRouter, Redirect, useHistory } from "react-router-dom";
import http from "../../config/axios";
import { toast } from "react-toastify";
import { AuthContext } from "../../context/AuthContext";

const Login = () => {
  const auth = useContext(AuthContext);

  const history = useHistory();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [type, setType] = useState("password");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    http
      .post("/users/login", { email, password })
      .then(({ data }) => {
        http.setToken(data.token);
        localStorage.setItem("access_token", data.token);
        auth.onLoggedIn(data.user, true);

        history.go("/");
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  if (auth.isAuthenticated) {
    return <Redirect to={{ pathname: "/" }} />;
  } else {
    return (
      <section className="login-banner">
        <div className="container">
          <div className="row ">
            <div className="col-md-8 offset-md-2 col-12 ">
              <div className="login-main">
                <div className="login-inner">
                  <div className="right">
                    <img
                      src="images/pre-order.png"
                      className="img-fluid"
                      alt=""
                      width="100"
                    />
                    <h3 className="pink text-center">Login</h3>
                    <h6 className="text-center">Login to Your Account</h6>
                    <form onSubmit={onSubmit}>
                      <div className="row">
                        <div className="col-12 form-group">
                          <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="form-control"
                            placeholder="Email Address"
                          />
                          <i className="fa fa-envelope" />{" "}
                        </div>
                        <div className="col-12 form-group">
                          <input
                            type={type}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="form-control"
                            placeholder="Password"
                          />
                          <i className="fa fa-lock" />
                          <button
                            onClick={() => {
                              type === "password"
                                ? setType("text")
                                : setType("password");
                            }}
                            className="icon"
                            type="button"
                          >
                            <i className="fa fa-eye" />
                          </button>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <div>
                          <label className="login-check">
                            Remember Me
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </div>
                        <div>
                          {" "}
                          <NavLink to="/forget_password" className="forgot">
                            {" "}
                            Forgot Password?
                          </NavLink>{" "}
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="pink-btn-nr form-control text-center"
                      >
                        {" "}
                        login <i className="fa fa-angle-right" />
                      </button>
                    </form>
                    <p className="text-center">
                      - Not a member? Register Now -
                    </p>
                    <NavLink
                      to="/register"
                      className="yel-btn-nr form-control text-center"
                    >
                      register <i className="fa fa-angle-right" />
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
};

export default withRouter(Login);
