import React, { useState, useEffect, useCallback } from "react";
import http from "../../config/axios";
import { NavLink, useParams } from "react-router-dom";
import SelectAddress from "../checkout/SelectAddress";
import { toast } from "react-toastify";
import Decrypt from "../../utils/Decrypt";

const Order_Details = (props) => {
  const [address, setAddress] = useState();
  const [order, setOrder] = useState({});

  const { id } = useParams();

  const onViewOrder = useCallback(async () => {
    try {
      const { data } = await http.get(
        `/orders/${id}?fields=slug,totalPrice,createdAt,status,orderItems.price,orderItems.title,orderItems.image,orderItems.weight,orderItems.size,orderItems.qty`
      );

      const decryptedData = Decrypt(data.data);

      setOrder(decryptedData);
      setAddress(decryptedData.address);
    } catch (error) {
      const { data } = error.response;
      props.history.push({
        pathname: `/order-log`,
      });
      toast.error(data.message);
    }
  }, [id, props]);

  useEffect(() => {
    window.scrollTo(0, 0);
    onViewOrder();
  }, [onViewOrder]);

  return (
    <div>
      <section className="inner-header-section">
        <div className="container">
          <div className="row">
            <div className="col-xl-7 col-lg-9 col-12">
              <h2>Order Details</h2>
              <h4 className="pink mt-4">Discover the unique village items!</h4>
            </div>
          </div>
        </div>
      </section>
      {/*inner-header-section end here*/}
      {/*order-detail start here*/}
      <section className="order-details p-70">
        <div className="container">
          <div className="order-detail">
            <div className="row">
              <div className="col-12">
                <h3>Order Details </h3>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-4 col-lg-3">
                <div className="box pink-bg">
                  <div className="media alig-items-center">
                    <div className="media-body">
                      <p>Order Number</p>
                      <h6>#{order.slug}</h6>
                    </div>
                    <i className="fas fa-hashtag" />
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4 col-lg-3">
                <div className="box yel-bg">
                  <div className="media alig-items-center">
                    <div className="media-body">
                      <p>Order Date</p>
                      <h6>
                        {new Date(order.createdAt).toDateString(undefined, {
                          timeZone: "Asia/Kolkata",
                        })}
                      </h6>
                    </div>
                    <i className="fa fa-calendar-alt" />
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4 col-lg-3">
                <div className="box white-bg">
                  <div className="media alig-items-center">
                    <div className="media-body">
                      <p>Status</p>
                      <h6>{order.status}</h6>
                    </div>
                    <i className="fa fa-rocket" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="row">
              <div className="col-md-12 col-12 d-flex justify-content-between">
                {address && (
                  <SelectAddress
                    address={address}
                    key={address.id}
                    requireAddressChange={true}
                  />
                )}
                <div className="left">
                  <div className="media align-items-center">
                    <div className="media-body">
                      <NavLink
                        to={`/track-order/${props.match.params.id}`}
                        className="yel-btn-nr text-center"
                      >
                        Track Order
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="my-cart summary">
            <div className="row">
              <div className="col-12">
                <h6>Summary </h6>
              </div>
            </div>
            <div className="row">
              <div className="col-12 table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Product</th>
                      <th className="text-center">QTY</th>
                      <th className="text-center">Price</th>
                      <th className="text-right">Sub total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {order.orderItems?.map((orderItem) => (
                      <tr className="customer-box">
                        <td>
                          <div className="media align-items-center d-sm-flex d-block d-sm-text-left">
                            {/* <img src={`${process.env.PUBLIC_URL}/images/product-1.png`} className="img-fluid" alt="" /> */}
                            <img
                              src={`${process.env.REACT_APP_CLOUDFRONT_PUBLIC_CDN}/${orderItem.image}`}
                              className="img-fluid"
                              alt=""
                            />
                            <div className="media-body">
                              <h4>{orderItem.title}</h4>
                              <h6>{orderItem.weight}</h6>
                            </div>
                          </div>
                        </td>
                        <td className="text-center">
                          <h4>{orderItem.qty}</h4>
                        </td>
                        <td className="text-center">
                          <h4>{orderItem.price}</h4>
                        </td>
                        <td className="text-right">
                          <h4>{orderItem.qty * orderItem.price}</h4>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <table className="border-top"></table>
                <table
                  className="inner-table"
                  style={{
                    float: "right",
                    width: "300px",
                    borderCollapse: "collapse",
                  }}
                >
                  <tbody>
                    <tr>
                      <td style={{ padding: "0 0 0 20px" }}>
                        <h5>Total:</h5>
                      </td>
                      <td style={{ padding: "0 0 0 20px", textAlign: "right" }}>
                        <h4>{order.totalPrice}</h4>{" "}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Order_Details;
